<template>
  <div>
    <section class="w-full space-y-6" v-if="load">
      <tabs
        link_type="button"
        :tabs="tabs"
        :component_name="current_item"
        @action="current_item = $event"
      />
      <main>
        <slide-transition v-for="(item, index) in items" :key="index">
          <figure
            class="w-full bg-white rounded ring-1 ring-gray-200"
            :data-title="`tab_${index + 1}`"
            v-show="current_item === `tab_${index + 1}`"
          >
            <header class="p-4">
              <p class="text-base font-semibold rtl:font-bold">
                {{ item["tab_title_" + $i18n.locale] }}
              </p>
            </header>
            <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
              <div class="w-full grid gap-4 grid-cols-1 sm:grid-cols-2">
                <div
                  class="form-group w-full"
                  v-for="(field, i) in item.items"
                  :key="i"
                  :class="{
                    'sm:col-span-2': parseInt(field.field.col_size) === 12,
                  }"
                >
                  <!-- checkbox or radio -->
                  <div
                    class="checkbox"
                    v-if="['boolean'].includes(field.field.type)"
                  >
                    <input
                      type="checkbox"
                      :name="field.key"
                      v-model="add_data[field.key]"
                    />

                    <label :for="field.key">{{
                      field["label_" + $i18n.locale]
                    }}</label>
                    <p class="error" v-if="add_errors[field.key]">
                      {{ (add_errors[field.key] || []).join(" ") }}
                    </p>
                  </div>
                  <!-- checkbox or radio -->
                  <!-- not textarea | array | boolean -->
                  <div
                    class="with-label space-y-3"
                    v-else-if="['array'].includes(field.field.type)"
                  >
                    <p class="error" v-if="add_errors[field.key]">
                      {{ (add_errors[field.key] || []).join(" ") }}
                    </p>

                    <!-- <input
                      :type="field.field.type"
                      :name="field.key"
                      :id="field.key"
                      v-model="add_data[field.key]"
                      min="1"
                    /> -->
                    <VueTagsInput
                      v-model="tag"
                      :tags="add_data[field.key]"
                      @tags-changed="
                        (newTags) => (add_data[field.key] = newTags)
                      "
                      class="ti-container"
                    />
                    <label :for="field.key">
                      {{ field["label_" + $i18n.locale] }}
                    </label>
                  </div>
                  <!-- not textarea | array | boolean -->
                  <!-- not textarea | array | boolean -->
                  <div
                    class="space-y-3"
                    v-else-if="['textarea'].includes(field.field.type)"
                  >
                    <div
                      class="with-label space-y-3"
                      v-if="!field.field.multi_lang"
                    >
                      <p class="error" v-if="add_errors[field.key]">
                        {{ (add_errors[field.key] || []).join(" ") }}
                      </p>
                      <textarea
                        :name="field.key"
                        :id="field.key"
                        v-model="add_data[field.key]"
                        placeholder="Type text here..."
                      ></textarea>
                      <label :for="field.key">
                        {{ field["label_" + $i18n.locale] }}
                      </label>
                    </div>
                    <div
                      class="with-label space-y-3"
                      v-else
                      v-for="(lang, lIndx) in add_data.langs"
                      :key="lIndx"
                    >
                      <p
                        class="error"
                        v-if="add_errors[`${field.key}.${lang}`]"
                      >
                        {{
                          (add_errors[`${field.key}.${lang}`] || []).join(" ")
                        }}
                      </p>

                      <textarea
                        placeholder="Type text here..."
                        :name="`${field.key}.${lang}`"
                        :id="`${field.key}.${lang}`"
                        v-model="(add_data[field.key] || {})[lang]"
                      ></textarea>
                      <label :for="`${field.key}.${lang}`">
                        {{ field["label_" + $i18n.locale] }} | {{ lang }}
                      </label>
                    </div>
                  </div>
                  <!-- not textarea | array | boolean -->
                  <!-- not textarea | array | boolean -->
                  <div
                    class="space-y-3"
                    v-else-if="
                      !['textarea', 'array', 'boolean', 'image'].includes(
                        field.field.type
                      )
                    "
                  >
                    <div class="with-label" v-if="!field.field.multi_lang">
                      <p class="error" v-if="add_errors[field.key]">
                        {{ (add_errors[field.key] || []).join(" ") }}
                      </p>

                      <input
                        :type="field.field.type"
                        :name="field.key"
                        :id="field.key"
                        v-model="add_data[field.key]"
                        min="1"
                      />
                      <label :for="field.key">
                        {{ field["label_" + $i18n.locale] }}
                      </label>
                    </div>
                    <div
                      class="with-label"
                      v-else
                      v-for="(lang, lIndx) in add_data.langs"
                      :key="lIndx"
                    >
                      <p
                        class="error"
                        v-if="add_errors[`${field.key}.${lang}`]"
                      >
                        {{
                          (add_errors[`${field.key}.${lang}`] || []).join(" ")
                        }}
                      </p>

                      <input
                        :type="field.field.type"
                        :name="`${field.key}.${lang}`"
                        :id="`${field.key}.${lang}`"
                        v-model="add_data[field.key][lang]"
                      />
                      <label :for="`${field.key}.${lang}`">
                        {{ field["label_" + $i18n.locale] }} | {{ lang }}
                      </label>
                    </div>
                  </div>
                  <figure
                    class="space-y-4"
                    v-else-if="field.field.type === 'image'"
                  >
                    <slide-transition>
                      <figcaption
                        v-if="add_data[field.key]"
                        class="relative table"
                      >
                        <img
                          :src="
                            add_data[field.key]
                              ? typeof add_data[field.key] === 'object'
                                ? convertImage(add_data[field.key])
                                : add_data[field.key]
                              : require('@/assets/images/placeholder.png')
                          "
                          @error="
                            (e) =>
                              (e.target.src = require('@/assets/images/placeholder.png'))
                          "
                          alt="category image"
                          class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                        />
                        <button
                          class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                          type="button"
                          @click.prevent="
                            () => clearImage('add_data', field.key)
                          "
                          v-if="typeof add_data[field.key] === 'object'"
                        >
                          <i class="fa-solid fa-xmark fa-sm"></i>
                        </button>
                      </figcaption>
                    </slide-transition>
                    <blockquote class="space-y-2">
                      <label class="block">
                        <span class="sr-only">Choose image</span>
                        <input
                          type="file"
                          accept="image/*"
                          class="custom-file !shadow-none"
                          ref="uploader"
                          @change="
                            (e) => $set(add_data, field.key, e.target.files[0])
                          "
                        />
                      </label>
                      <p class="error" v-if="add_errors[field.key]">
                        {{ add_errors[field.key].join(" ") }}
                      </p>
                    </blockquote>
                  </figure>
                  <!-- not textarea | array | boolean -->
                </div>
              </div>
            </blockquote>
          </figure>
        </slide-transition>
        <button-with-loader
          btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
          class="mt-6"
          :disabled="add_disabled"
          @click="updateSettings"
          >Save changes</button-with-loader
        >
      </main>
    </section>
    <section class="w-full space-y-6" v-else>
      <header class="w-full overflow-auto p-2 flex items-center gap-4">
        <div v-for="h in 4" :key="h">
          <div class="w-32 h-8 rounded bg-gray-200 animate-pulse"></div>
        </div>
      </header>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4 border-b border-b-gray-100">
          <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
        </header>
        <blockquote class="p-4 space-y-4">
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            v-for="i in 6"
            :key="i"
          >
            <div class="w-full space-y-3">
              <div class="w-1/5 h-4 rounded bg-gray-200 animate-pulse"></div>
              <div class="w-full h-10 rounded bg-gray-200 animate-pulse"></div>
            </div>
          </div>
        </blockquote>
      </figure>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import ButtonWithLoader from "../basics/ButtonWithLoader.vue";

import Tabs from "../basics/Tabs.vue";
export default {
  components: { Tabs, ButtonWithLoader, VueTagsInput },
  name: "GeneralSettings",
  data() {
    return {
      items: [],
      load: false,
      add_data: {},
      add_errors: {},
      add_disabled: false,
      tabs: [],
      current_item: "tab_1",
      tag: "",
    };
  },
  created() {
    this.getItems();
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getItems() {
      try {
        const { result } = await this.getRoute("settings");
        const { settings, settings_values } = result;
        this.items = settings.map((e) => {
          return {
            ...e,
          };
        });

        this.add_data = {
          ...settings_values,

          site_keywords:
            settings_values?.site_keywords?.map((e) => {
              return {
                text: e,
              };
            }) || [],
        };

        this.tabs = settings.map((e, i) => {
          return {
            title: e["tab_title_" + this.$i18n.locale],
            name: `tab_${i + 1}`,
            error: false,
            keys: e.items.map((e) => e.key),
          };
        });
        // return { results: result.branches };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.load = true;
      }
    },
    async updateSettings() {
      this.add_disabled = true;
      this.add_errors = {};
      this.tabs = this.tabs.map((e) => {
        return {
          ...e,
          error: false,
        };
      });
      try {
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (item || typeof item === "number") {
            if (Array.isArray(item)) {
              for (const index in item) {
                if (key === "site_keywords") {
                  fd.append(
                    `${key}[${index}]`,
                    item[index]?.text || item[index]
                  );
                } else {
                  fd.append(`${key}[${index}]`, item[index]);
                }
              }
            } else if (/(image|logo)/gi.test(key)) {
              if (typeof item === "object") {
                fd.append(key, item, item.name);
              } else {
                fd.delete(key);
              }
            } else if (["site_name", "site_desc", "address_1"].includes(key)) {
              for (const lang of this.add_data.langs) {
                fd.append(`${key}[${lang}]`, item[lang]);
              }
            } else {
              fd.append(key, item);
            }
          }
        }

        const { data } = await this.axios.post("settings/update", fd);
        this.createAlert(data.message);
        this.getItems();
        this.$refs.uploader.value = null;
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
          for (const item of this.tabs) {
            const someErrors = Object.keys(responseError).some((e) =>
              item.keys.includes(e)
            );
            if (someErrors) {
              this.$set(
                this.tabs[this.tabs.findIndex((e) => e.name === item.name)],
                "error",
                true
              );
            }
          }
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    clearImage(type, key) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], key, null);
    },
  },
};
</script>
<style lang="scss">
.ti-container {
  max-width: 100% !important;
  .ti-new-tag-input-wrapper,
  .ti-input {
    padding: 0;
    margin: 0;
  }
  .ti-input {
    @apply rounded border-gray-200 bg-gray-100 p-3;
  }
  .ti-tag {
    @apply px-1.5 py-1 rounded-sm self-end;
  }
  .ti-new-tag-input {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
</style>
