<template>
  <div
    class="z-20 fixed xl:sticky opacity-0 top-0 pointer-events-none invisible xl:opacity-100 xl:pointer-events-auto xl:visible left-full xl:left-0 bg-gray-800/20 xl:bg-transparent w-full xl:w-auto h-screen"
    @click="openCloseSideBar"
    ref="sideBar"
  >
    <aside
      class="bg-gray-800 overflow-y-auto flex flex-col top-0 w-screen max-w-[17rem] h-full max-h-screen transition-all"
    >
      <div class="w-full border-b border-gray-700 p-3">
        <div>
          <img
            src="@/assets/images/white-logo.png"
            alt="logo image"
            class="w-auto max-h-[2.9rem]"
          />
        </div>
      </div>
      <ul class="w-full space-y-2 flex-1 overflow-y-auto p-4 links-container">
        <li v-for="(item, index) in pages" :key="index">
          <div
            v-if="item.children"
            class="space-y-2 pt-3 pb-2 border-y border-y-gray-700"
          >
            <p class="font-medium line-clamp-1 text-xs px-3 text-gray-700">
              {{ item.name }}
            </p>
            <ul class="w-full space-y-2 flex-1 overflow-y-auto links-container">
              <li v-for="(item, index) in item.children" :key="index">
                <router-link
                  :to="item.path"
                  class="flex gap-4 py-2 px-3 rounded"
                  active-class="active-tab-pills"
                >
                  <div>
                    <i :class="item.icon"></i>
                  </div>
                  <div class="flex-1 self-center">
                    <p
                      class="font-medium rtl:font-semibold line-clamp-2 text-sm"
                    >
                      {{ item.name }}
                    </p>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <router-link
            :to="item.path"
            class="flex gap-4 py-2 px-3 rounded"
            :exact="item.path === '/'"
            active-class="active-tab-pills"
            v-else
          >
            <div>
              <i :class="item.icon"></i>
            </div>
            <div class="flex-1 self-center">
              <p class="font-medium rtl:font-semibold line-clamp-2 text-sm">
                {{ item.name }}
              </p>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="p-4 hidden xl:block">
        <button
          class="text-white w-12 h-12 bg-gray-700 flex items-center justify-center rounded-full"
          v-tippy
          content="Logout"
          @click.prevent="logout"
        >
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </button>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      pages: [
        {
          name: "Dashboard",
          icon: "fa-solid fa-chart-line",
          path: "/",
        },

        {
          name: "Orders",
          icon: "fa-solid fa-shopping-cart",
          path: "/orders",
        },
        // {
        //   name: "Incomes",
        //   icon: "fa-solid fa-coins",
        //   path: "/incomes",
        // },

        {
          name: "Categories",
          icon: "fa-solid fa-cube",
          path: "/categories",
        },

        {
          name: "Brands",
          icon: "fa-solid fa-ranking-star",
          path: "/brands",
        },
        {
          name: "Coupons",
          icon: "fa-solid fa-percent",
          path: "/coupons",
        },
        {
          name: "Products",
          icon: "fa-solid fa-tags",
          path: "/products",
        },
        {
          name: "Customers",
          icon: "fa-solid fa-user-group",
          path: "/customers",
        },
        {
          name: "Employees",
          icon: "fa-solid fa-user-group",
          path: "/employees",
        },

        // {
        //   name: "Support",
        //   icon: "fa-solid fa-ticket",
        //   path: "/support",
        // },

        {
          name: "Notifications",
          icon: "fa-solid fa-bell",
          path: "/notifications",
        },
        {
          name: "Settings",
          children: [
            {
              name: "Settings",
              icon: "fa-solid fa-gear",
              path: "/settings",
            },
            {
              name: "Sizes",
              icon: "fa-solid fa-weight-scale",
              path: "/sizes",
            },
            {
              name: "Rates",
              icon: "fa-solid fa-star",
              path: "/rates",
            },
            {
              name: "Site appearance",
              icon: "fa-solid fa-paintbrush",
              path: "/site-appearance/slides",
            },
            {
              name: "Email templates",
              icon: "fa-solid fa-envelope-circle-check",
              path: "/email-templates",
            },
            {
              name: "Notification templates",
              icon: "fa-solid fa-layer-group",
              path: "/notification-templates",
            },
          ],
        },
      ],
    };
  },
  methods: {
    openCloseSideBar() {
      const sidebar = this.$refs.sideBar;
      if (!sidebar) return;
      if (sidebar.classList.contains("opacity-0")) {
        sidebar.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        sidebar.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
  },
};
</script>
<style lang="scss">
.links-container {
  i {
    @apply text-gray-300;
  }
  p {
    @apply text-gray-200;
  }
  a:not(.active-tab-pills) {
    @apply hover:bg-gray-700 transition-all;
    i {
      @apply text-gray-300;
    }
    p {
      @apply text-gray-200;
    }
  }
  .active-tab-pills {
    i,
    p {
      @apply text-white;
    }
    p {
      @apply font-bold;
    }
  }
}
</style>
