<template>
  <section class="w-full p-6">
    <form
      class="w-full mx-auto space-y-6"
      @submit.prevent
      novalidate
      v-if="load"
    >
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Product details</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <figure class="space-y-4">
            <p class="text-sm font-medium rtl:font-semibold">Image</p>
            <slide-transition>
              <figcaption v-if="add_data.image" class="relative table">
                <img
                  :src="
                    add_data.image
                      ? typeof add_data.image === 'object'
                        ? convertImage(add_data.image)
                        : add_data.image
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="branch image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                  type="button"
                  @click.prevent="() => clearImage('add_data')"
                  v-if="typeof add_data.image === 'object'"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(add_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="add_errors.image">
                {{ add_errors.image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="name_ar"> Name | Ar </label>
                <input
                  type="text"
                  name="name_ar"
                  id="name_ar"
                  v-model="add_data.ar.name"
                  placeholder="الاسم"
                  v-rtl
                />
                <p class="error">
                  {{ (add_errors["ar.name"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="name_en"> Name | En </label>
                <input
                  type="text"
                  name="name_en"
                  id="name_en"
                  v-model="add_data.en.name"
                  placeholder="Name"
                  v-ltr
                />
                <p class="error">
                  {{ (add_errors["en.name"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="form-group space-y-3 flex-1">
              <label for="short_desc_ar"> Short description | Ar </label>
              <textarea
                name="short_desc_ar"
                id="short_desc_ar"
                v-model="add_data.ar.short_desc"
                placeholder="وصف قصير"
                v-rtl
              />
              <p class="error">
                {{ (add_errors["ar.short_desc"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3 flex-1">
              <label for="short_desc_en"> Short description | En </label>
              <textarea
                name="short_desc_en"
                id="short_desc_en"
                v-model="add_data.en.short_desc"
                placeholder="Short description"
                v-ltr
              />
              <p class="error">
                {{ (add_errors["en.short_desc"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="desc_ar"> Description | Ar </label>
            <editor v-model="add_data.ar.desc" id="desc_ar" placeholder="..." />
            <p class="error">
              {{ (add_errors["ar.desc"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="desc_en"> Description | En </label>
            <editor v-model="add_data.en.desc" id="desc_en" placeholder="..." />

            <p class="error">
              {{ (add_errors["en.desc"] || []).join(" ") }}
            </p>
          </div>

          <div class="form-group space-y-3">
            <label for="sku"> SKU </label>
            <input
              type="text"
              name="sku"
              id="sku"
              v-model="add_data.sku"
              placeholder="ABCDEFG"
              v-ltr
            />
            <p class="error">
              {{ (add_errors["sku"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="prepared_time"> Prepared time (Days)</label>
            <input
              type="number"
              name="prepared_time"
              id="prepared_time"
              v-model="add_data.prepared_time"
              placeholder="1"
              min="1"
            />
            <p class="error">
              {{ (add_errors["prepared_time"] || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Sizes</p>
        </header>
        <blockquote
          class="w-full p-5 !pt-1 border-t border-gray-200 divide-y divide-gray-200"
        >
          <div
            class="w-full space-y-4 py-4"
            v-for="(size, index) in add_data.sizes"
            :key="index"
          >
            <div class="form-group space-y-3 flex-1">
              <label :for="`size_${index}`"> Size </label>
              <t-rich-select
                :options="sizes"
                placeholder="Select"
                :text-attribute="'name'"
                value-attribute="id"
                :clear-able="true"
                v-model="add_data.sizes[index].id"
              />
              <p class="error">
                {{ (add_errors[`sizes.${index}.id`] || []).join(" ") }}
              </p>
            </div>
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label :for="`sizes.${index}.qty`"> Quantity</label>
                <input
                  type="number"
                  step="any"
                  :name="`sizes.${index}.qty`"
                  :id="`sizes.${index}.qty`"
                  v-model="add_data.sizes[index].qty"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors[`sizes.${index}.qty`] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label :for="`sizes.${index}.price`"> Price </label>
                <input
                  type="number"
                  step="any"
                  :name="`sizes.${index}.price`"
                  :id="`sizes.${index}.price`"
                  v-model="add_data.sizes[index].price"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors[`sizes.${index}.price`] || []).join(" ") }}
                </p>
              </div>
            </div>
            <div class="actions-group !mt-2">
              <div v-if="index !== 0">
                <button
                  class="py-2 px-4 rounded bg-red-500/10 text-red-500 font-medium rtl:font-semibold text-center inline-flex gap-2 items-center text-xs"
                  @click.prevent="add_data.sizes.splice(index, 1)"
                >
                  <span>Remove</span>
                </button>
              </div>
            </div>
          </div>
          <button
            class="py-2 px-4 rounded bg-primary text-white font-medium rtl:font-semibold text-center inline-flex gap-2 items-center text-xs !mt-2 !border-none"
            @click.prevent="addNewSize"
          >
            <span>Add</span>
          </button>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200 hidden">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">
            Quantity and price
          </p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="form-group space-y-3 flex-1">
            <label for="qty"> Quantity </label>
            <input
              type="number"
              name="qty"
              id="qty"
              v-model="add_data.qty"
              placeholder="1"
              min="1"
            />
            <p class="error">
              {{ (add_errors["qty"] || []).join(" ") }}
            </p>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="price"> Price </label>
                <input
                  type="number"
                  step="any"
                  name="price"
                  id="price"
                  v-model="add_data.price"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["price"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="sale_price"> Sale price </label>
                <input
                  type="number"
                  step="any"
                  name="sale_price"
                  id="sale_price"
                  v-model="add_data.sale_price"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["sale_price"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Related products</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="form-group space-y-3 flex-1">
            <label for="related_products"> Products </label>
            <select-with-limit
              placeholder="Select"
              :text="'name'"
              id="id"
              :fetch="GetProducts"
              v-model="add_data.related_products"
              ref="productsSearch"
            />
            <p class="error">
              {{
                Object.keys(add_errors)
                  .filter((e) => /related_products/gi.test(e))
                  .map((e) => add_errors[e])
                  .join(/\n/)
              }}
            </p>
            <div v-if="products || products?.length">
              <ul class="inline-flex gap-2 flex-wrap">
                <li v-for="(product, index) in products" :key="index">
                  <div
                    class="w-full bg-white p-4 rounded-xl border border-slate-100 inline-flex gap-4"
                  >
                    <img
                      class="shrink-0 w-10 h-10 rounded-full object-cover"
                      :src="
                        product.image ||
                        require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                    />
                    <div class="flex-1 space-y-1">
                      <p class="text-sm font-bold text-slate-800">
                        {{ product.name }}
                      </p>
                      <p class="text-sm text-primary">
                        {{ product.price }} SAR
                      </p>
                    </div>
                    <button
                      class="shrink-0 text-red-500"
                      type="button"
                      @click.prevent="DeleteCurrentProduct(product.id)"
                    >
                      <i class="fa-solid fa-trash fa-sm"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Other details</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="form-group space-y-3 flex-1">
            <label for="categories_ids"> Categories </label>
            <select-with-limit
              :options="categories"
              placeholder="Select"
              :text="'name'"
              id="id"
              v-model="add_data.categories_ids"
            />
            <p class="error">
              {{
                Object.keys(add_errors)
                  .filter((e) => /categories_ids/gi.test(e))
                  .map((e) => add_errors[e])
                  .join(/\n/)
              }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="brand_id"> Brand </label>
            <t-rich-select
              :options="brands"
              placeholder="Select"
              :text-attribute="'name'"
              value-attribute="id"
              :clear-able="true"
              :closeOnSelect="false"
              v-model="add_data.brand_id"
            />

            <p class="error">
              {{ (add_errors["brand_id"] || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <ul class="space-y-4">
        <li class="checkbox">
          <input
            type="checkbox"
            name="has_discount"
            id="has_discount"
            :true-value="1"
            :false-value="0"
            v-model="add_data.has_discount"
          />
          <label
            for="has_discount"
            class="text-sm font-medium rtl:font-semibold capitalize"
            >Has discount</label
          >
        </li>
      </ul>
      <slide-transition>
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-show="!!add_data.has_discount"
        >
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Discount</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="discount_type"> Discount type </label>
                  <select
                    name="discount_type"
                    id="discount_type"
                    v-model="add_data.discount_type"
                    class="custom-select"
                  >
                    <option value="" selected disabled>- select --</option>
                    <option value="fixed">Fixed</option>
                    <option value="percentage">Percentage</option>
                  </select>
                  <p class="error">
                    {{ (add_errors["discount_type"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="discount_value"> Discount value </label>
                  <input
                    type="number"
                    name="discount_value"
                    id="discount_value"
                    v-model="add_data.discount_value"
                    placeholder="1"
                    min="1"
                  />
                  <p class="error">
                    {{ (add_errors["discount_value"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="flex-1 form-group space-y-3">
                  <label for="discount_start"> Start at </label>
                  <t-datepicker
                    placeholder="xxxx-xx-xx"
                    v-model="add_data.discount_start"
                    :clearable="false"
                    id="discount_start"
                    userFormat="Y-m-d"
                    :weekStart="6"
                  />
                  <p class="error">
                    {{ (add_errors.discount_start || []).join(" ") }}
                  </p>
                </div>
                <div class="flex-1 form-group space-y-3">
                  <label for="discount_end"> End at </label>
                  <t-datepicker
                    placeholder="xxxx-xx-xx"
                    v-model="add_data.discount_end"
                    :clearable="false"
                    id="discount_end"
                    userFormat="Y-m-d"
                    :weekStart="6"
                    :minDate="add_data.discount_start"
                  />
                  <p class="error">
                    {{ (add_errors.discount_end || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
          </blockquote>
        </figure>
      </slide-transition>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Seo details</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="form-group space-y-3 flex-1">
            <label for="seo_title"> Title </label>
            <input
              type="text"
              name="seo_title"
              id="seo_title"
              v-model="add_data.seo_title"
              placeholder="..."
            />
            <p class="error">
              {{ (add_errors["seo_title"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="seo_desc"> Description </label>
            <textarea v-model="add_data.seo_desc"></textarea>
            <p class="error">
              {{ (add_errors["seo_desc"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="seo_keywords"> Keywords </label>
            <VueTagsInput
              v-model="tag"
              :tags="add_data.seo_keywords"
              @tags-changed="(newTags) => (add_data.seo_keywords = newTags)"
              class="ti-container"
            />
            <p class="error">
              {{ (add_errors["seo_keywords"] || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">More images</p>
        </header>
        <blockquote class="w-full p-6 border-t border-gray-200 space-y-4">
          <div class="w-full space-y-3">
            <label
              for="images_uploader"
              class="w-full rounded-lg !py-12 px-8 bg-gray-400/10 text-gray-400 text-center border-dashed border-2 border-gray-400/50 block cursor-pointer"
              @dragover="
                $event.target.classList.replace('border-dashed', 'border')
              "
              @dragleave="
                $event.target.classList.replace('border', 'border-dashed')
              "
              @dragend="
                $event.target.classList.replace('border', 'border-dashed')
              "
              @drop="dropControl($event)"
            >
              <span
                class="w-14 h-14 rounded-full flex items-center justify-center mx-auto bg-gray-400/20 text-xl mb-4"
              >
                <i class="fa-solid fa-arrow-up-from-bracket"></i>
              </span>
              <p class="text-sm font-medium rtl:font-semibold">
                Drop and Drag or browse images
              </p>
            </label>
            <input
              type="file"
              name="images_uploader"
              id="images_uploader"
              hidden
              class="hidden"
              multiple
              @change="attachmentsUploader"
              accept="image/*"
            />
            <div class="block mt-6 w-auto">
              <div class="flex items-start gap-4 flex-wrap">
                <div v-for="(img, imgIdx) in add_data.images" :key="imgIdx">
                  <div class="w-20 h-20 p-1 rounded ring-2 ring-gray-300">
                    <img
                      :src="!img.id ? convertImage(img) : img.image"
                      alt="upload"
                      class="w-full rounded h-full object-cover"
                    />
                  </div>
                  <button
                    class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm z-[1] relative"
                    :disabled="disables[`image_${imgIdx}`]"
                    @click.prevent="
                      !img.id
                        ? add_data.images.splice(imgIdx, 1)
                        : deleteImage(`image_${imgIdx}`, img.id)
                    "
                  >
                    <i
                      class="fa-solid fa-spinner fa-spin"
                      v-if="disables[`image_${imgIdx}`]"
                    ></i>
                    <i class="fa-solid fa-trash" v-else></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
        :disabled="add_disabled"
        @click="addProduct"
        >Save</button-with-loader
      >
    </form>
    <div class="w-full space-y-6" v-else>
      <figure
        class="w-full bg-white rounded ring-1 ring-gray-200"
        v-for="l in 3"
        :key="l"
      >
        <header class="p-4 border-b border-b-gray-100">
          <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
        </header>
        <blockquote class="p-4 space-y-4">
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            v-for="i in 2"
            :key="i"
          >
            <div class="w-full space-y-3">
              <div class="w-1/5 h-4 rounded bg-gray-200 animate-pulse"></div>
              <div class="w-full h-10 rounded bg-gray-200 animate-pulse"></div>
            </div>
          </div>
        </blockquote>
      </figure>
    </div>
  </section>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions } from "vuex";
import Editor from "@/components/basics/Editor.vue";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: { ButtonWithLoader, Editor, VueTagsInput },
  name: "UpdateProduct",
  data() {
    return {
      add_data: {},
      add_errors: {},
      add_disabled: false,

      load: false,
      categories: [],
      brands: [],
      disables: {},
      size: [],
      tag: "",
    };
  },
  async created() {
    Promise.all([
      await this.getProduct(),
      await this.getCategories(),
      await this.getBrands(),
      await this.getSizes(),
    ]).finally(() => (this.load = true));
  },
  computed: {
    productId() {
      return this.$route.params.id;
    },
    // filterSizes() {
    //   const selectedSizes = this.add_data.sizes;
    //   if (!selectedSizes.length) return this.sizes;
    //   const ids = selectedSizes.map((e) => e.id);
    //   return this.sizes.filter((e) => !ids.includes(e.id));
    // },
    products() {
      const relatedProducts =
        this.$refs?.productsSearch?.$refs?.selectComponent;
      const products = relatedProducts?.$data?.filteredOptions;
      const ids = this.add_data.related_products;

      return products
        ?.filter((ev) => {
          if (ids?.includes(ev?.raw?.id)) {
            return ev;
          }
          return undefined;
        })
        ?.map((ev) => ev.raw);
    },
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    async GetProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            page: 1,
          },
        });

        // console.log(result.products.data);
        return { results: result.products?.data || [] };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSizes() {
      try {
        const { result } = await this.getRoute("sizes");
        this.sizes = result.sizes.map((e) => {
          return {
            name: e.name,
            id: e.id,
          };
        });
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getCategories() {
      try {
        const { result } = await this.getRoute("categories");
        this.categories = result.categories.map((e) => {
          return {
            name: e.name,
            id: e.id,
          };
        });
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getBrands() {
      try {
        const { result } = await this.getRoute("brands");
        this.brands = result.brands.map((e) => {
          return {
            name: e.name,
            id: e.id,
          };
        });
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getProduct() {
      try {
        const { result } = await this.getRoute(`/products/${this.productId}`);
        const { product } = result;
        this.add_data = {
          ...product,
          brand_id: product?.brand?.id,
          categories_ids: product?.categories?.map((e) => e.id),
          related_products: product?.related_products?.map((e) => e.id),
          ar: product.ar ? product.ar : {},
          en: product.en ? product.en : {},
          has_discount: product.discount_type ? 1 : 0,
          seo_keywords: product?.seo_keywords?.map((e) => ({ text: e })),
          sizes: product.sizes.length
            ? product.sizes
            : [
                {
                  id: null,
                  qty: null,
                  price: null,
                },
              ],
        };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        this.$router.push("/products");
      }
    },
    async addProduct() {
      this.add_disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      for (const key in this.add_data) {
        const item = this.add_data[key];
        if (item) {
          if (key === "image") {
            if (typeof item === "object") {
              fd.append(key, item, item.name);
            } else {
              fd.delete(key);
            }
          } else if (["ar", "en"].includes(key)) {
            for (const translateKey in item) {
              fd.append(`${key}[${translateKey}]`, item[translateKey]);
            }
          } else if (key === "images") {
            item.forEach((img, index) => {
              if (!img?.id) {
                fd.append(`${key}[${index}]`, img, img.name);
              } else {
                fd.delete(`${key}[${index}]`);
              }
            });
          } else if (key === "sizes") {
            for (const arrKey in item) {
              for (const item_key in item[arrKey]) {
                fd.append(
                  `${key}[${arrKey}][${item_key}]`,
                  item[arrKey][item_key]
                );
              }
            }
          } else if (key === "seo_keywords") {
            for (const index in item) {
              console.log(item[index]);
              fd.append("seo_keywords[" + index + "]", item[index].text);
            }
          } else if (key === "related_products") {
            for (const arrKey in item) {
              fd.append(`${key}[${arrKey}][id]`, item[arrKey]);
            }
          } else if (Array.isArray(item)) {
            for (const arrKey in item) {
              fd.append(`${key}[${arrKey}]`, item[arrKey]?.id || item[arrKey]);
            }
          } else {
            fd.append(key, item);
          }
        }
      }
      try {
        const { data } = await this.axios.post("products/update", fd);
        this.$router.push("/products");
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    async deleteImage(type, id) {
      const { isConfirmed } = await this.$swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Yes, delete",
        denyButtonText: `No`,
      });

      if (isConfirmed) {
        // this.disables[type] = true;
        this.$set(this.disables, type, true);

        try {
          const { data } = await this.axios.post(
            `products/delete_images/${id}`
          );
          this.createAlert(data.message);
        } catch (error) {
          const err = error?.response;
          if (!err || !err?.data) {
            this.createAlert("there is something went wrong", "error");
            return;
          }

          this.createAlert(err?.message, "error");
        } finally {
          this.disables = {};
        }
      }
    },
    dropControl(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files) {
        files.forEach((file) => {
          this.add_data.images.push(file);
        });
        e.currentTarget.classList.replace("border", "border-dashed");
      }
    },
    attachmentsUploader(e) {
      e.preventDefault();
      e.target.files.forEach((file) => {
        this.add_data.images.push(file);
      });
    },
    uploadMainImage(e) {
      e.preventDefault();
      this.add_data.image = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },

    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    addNewSize() {
      const length = this.add_data.sizes.length - 1;
      const lastItem = this.add_data.sizes[length];
      if (!lastItem) return;
      if (!lastItem.id) {
        this.$set(this.add_errors, `sizes.${length}.id`, ["Size is required"]);
        return;
      }
      this.add_data.sizes.push({
        id: null,
        qty: null,
        price: null,
      });
    },
    DeleteCurrentProduct(id) {
      const products = this.add_data.related_products;
      this.$set(
        this.add_data,
        "related_products",
        products?.filter((ev) => ev !== id)
      );
    },
  },
};
</script>
