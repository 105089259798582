<template>
  <div>
    <section class="w-full p-6 space-y-6">
      <Table
        title="Rate types"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div>
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New type</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.status === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Name</th>
          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              {{ item.name }}
            </td>

            <td>
              <p class="status capitalize" :class="item_status[item.status]">
                {{ item.status }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div>
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="'Update'"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="5" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </section>
    <Modal
      title="Add type"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors['en.name']">
                {{ add_errors["en.name"].join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.en.name"
                v-ltr
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors['ar.name']">
                {{ add_errors["ar.name"].join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="add_data.ar.name"
                v-rtl
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="'Update'"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors['en.name']">
                {{ edit_errors["en.name"].join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.en.name"
                v-ltr
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors['ar.name']">
                {{ edit_errors["ar.name"].join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="edit_data.ar.name"
                v-rtl
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
export default {
  name: "RateTypes",
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        en: {},
        ar: {},
      },
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      status: ["all", "on", "off"],
      pagination: {
        status: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  async created() {
    await Promise.all([this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        this.add_data = {
          en: {},
          ar: {},
        };

        this.add_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "rate_types",
          options: {
            ...others,
            status: others.status ? others.status : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.rate_types;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `rate_types/update_status/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`rate_types/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post("rate_types/add", this.add_data);
        this.add_active = false;
        this.add_data = {};
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post(
          "rate_types/update",
          this.edit_data
        );
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);

        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },

    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        ar: item.ar ? item.ar : {},
        en: item.en ? item.en : {},
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
