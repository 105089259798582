<template>
  <div>
    <section class="w-full flex flex-col lg:flex-row gap-6 p-6">
      <aside class="w-full lg:w-1/4 xl:max-w-xs" ref="settingsRefParent">
        <button
          class="w-full px-4 py-2 rounded ring-1 ring-gray-200 bg-white flex lg:hidden items-center gap-4"
          @click.prevent="openSettingsDropdown"
        >
          <div class="flex-1">
            <p
              class="text-sm font-semibold rtl:font-bold text-gray-600 text-left rtl:text-right"
            >
              Settings
            </p>
          </div>
          <div>
            <button class="w-8 h-8 rounded-full bg-gray-50 hover:bg-gray-100">
              <i class="fa-solid fa-angle-down fa-sm"></i>
            </button>
          </div>
        </button>
        <div class="w-full space-y-3 hidden lg:block">
          <div class="w-full">
            <Button
              title="General"
              :isComponent="componentName === 'GeneralSettings'"
              component="GeneralSettings"
              :content="'Update general information'"
              :icon="'fa-solid fa-globe'"
              @change="(e) => $emit('change', e)"
            />
          </div>
          <div class="w-full">
            <Button
              title="Account settings"
              :isComponent="componentName === 'PersonalAccount'"
              component="PersonalAccount"
              :content="'Review and modify account information'"
              :icon="'fa-solid fa-user'"
              @change="(e) => $emit('change', e)"
            />
          </div>
          <div class="w-full">
            <Button
              title="Bank accounts"
              :isComponent="componentName === 'BankAccounts'"
              component="BankAccounts"
              :content="'Add and select your main bank account'"
              :icon="'fa-solid fa-wallet'"
              @change="(e) => $emit('change', e)"
            />
          </div>
          <!-- <div class="w-full">
            <Button
              title="Stores data"
              :isComponent="componentName === 'DiscountSettings'"
              component="DiscountSettings"
              :content="'Modify business information and communication meeting'"
              :icon="'fa-solid fa-store'"
              @change="(e) => $emit('change', e)"
            />
          </div>
          <div class="w-full">
            <Button
              title="Discount"
              :isComponent="componentName === 'DiscountSettings'"
              component="DiscountSettings"
              :content="'Control your discounts'"
              :icon="'fa-solid fa-percent'"
              @change="(e) => $emit('change', e)"
            />
          </div> -->
        </div>
      </aside>
      <main class="w-full lg:w-3/4 xl:flex-1">
        <slot></slot>
      </main>
    </section>
  </div>
</template>

<script>
import Button from "@/components/settings/Button.vue";
export default {
  name: "ContainerWrapper",
  components: { Button },
  props: {
    componentName: {
      type: String,
      default: "PersonalAccount",
    },
  },
  methods: {
    openSettingsDropdown() {
      const parent = this.$refs.settingsRefParent;
      const dropdown = parent?.children[1];
      const currentChild = parent?.children[0];
      const icon = currentChild?.querySelector(".fa-sm");
      if (!parent || !dropdown || !currentChild) return;

      dropdown.classList.toggle("!block");
      currentChild.classList.toggle("mb-4");
      icon.classList.replace(
        dropdown.classList.contains("!block") ? "fa-angle-down" : "fa-angle-up",
        dropdown.classList.contains("!block") ? "fa-angle-up" : "fa-angle-down"
      );
    },
  },
};
</script>
