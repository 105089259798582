import Vue from "vue";

export default async function auth({ next, store, to, from }) {
  const token = store.state.token || Vue.$cookies.get("token");
  const isExisted = Object.keys(store.state.auth.user_info).length !== 0;
  if (!token) {
    if (!["Login"].includes(to.name)) {
      return next({
        name: "Login",
        query: {
          redirect: ["Login"].includes(from.name) ? "/" : from.fullPath,
        },
      });
    }
    return next();
  }

  if (["Login"].includes(to.name)) {
    return next({
      path: from.path || "/",
    });
  }
  store.commit("auth/SET_USER", { token });
  if (isExisted) return next();
  await fetchProfile(store, token, next);

  return next();
}

async function fetchProfile(store, token, next) {
  try {
    const { result } = await store.dispatch("getting/getRoute", {
      name: "profile",
    });

    store.commit("auth/SET_USER", {
      profile: result.profile,
      token,
    });
  } catch (err) {
    Vue.$cookies.remove("token");
    window.location.reload();
    const res = err?.response;
  }
}
