<template>
  <quill-editor
    v-model="content"
    :options="{ ...options, placeholder }"
    @change="$emit('input', $event.html)"
  >
  </quill-editor>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: { quillEditor },

  props: {
    value: {
      type: String,
      default: String,
    },
    placeholder: {
      type: String,
      default: "Type your content here...",
    },
  },

  data() {
    return {
      // val: this.value,
      content: this.value,

      options: {
        height: 250,
        menubar: false,
        statusbar: false,
        content_style:
          "body { font-family:Cairo,Quicksand,Helvetica,Arial,sans-serif; font-size:14px }",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["link", "image"],
          ],
        },
      },
    };
  },
};
</script>

<style lang="scss">
.ql-toolbar,
.ql-container {
  border: none !important;
}
.ql-toolbar {
  @apply bg-gray-200;
}
.quill-editor {
  @apply rounded bg-gray-100 ring-1 ring-gray-200;
  * {
    font-family: "Montserrat", "Cairo", sans-serif !important;
  }
}
</style>
