<template>
  <div>
    <section class="w-full p-6 space-y-6">
      <Table
        title="Stores"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <!-- <template #actions>
          <div>
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New store</span>
            </button>
          </div>
        </template> -->
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.status === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Store</th>
          <th>Region</th>
          <th>City</th>
          <th>District</th>
          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="7" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="flex items-center gap-3">
                <div class="w-10 h-10">
                  <img
                    :src="
                      item.logo || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="store logo"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
                <span
                  class="inline-block line-clamp-2 max-w-sm whitespace-normal"
                >
                  {{ item["name_" + $i18n.locale] }}
                </span>
              </div>
            </td>

            <td>
              {{ (item.region || {})["name_" + $i18n.locale] || "-" }}
            </td>
            <td>
              {{ (item.city || {})["name_" + $i18n.locale] || "-" }}
            </td>
            <td>
              {{ (item.district || {})["name_" + $i18n.locale] || "-" }}
            </td>
            <td>
              <p class="status capitalize" :class="item_status[item.status]">
                {{ item.status }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div>
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <!-- <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="'Update'"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div> -->
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="7" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </section>
    <Modal
      title="Add store"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <blockquote class="space-y-2">
              <slide-transition>
                <figcaption v-if="add_data.logo" class="relative table">
                  <img
                    :src="
                      add_data.logo
                        ? convertImage(add_data.logo)
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="logo"
                    class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                  />
                  <button
                    class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                    type="button"
                    @click.prevent="
                      () => clearImage('add_data', 'logo', 'logoUploader')
                    "
                  >
                    <i class="fa-solid fa-xmark fa-sm"></i>
                  </button>
                </figcaption>
              </slide-transition>
              <p class="text-gray-600 font-semibold rtl:font-bold text-sm">
                Logo
              </p>
              <label class="block">
                <span class="sr-only">Choose Logo</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="logoUploader"
                  @change="(e) => $set(add_data, 'logo', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="add_errors.logo">
                {{ add_errors.logo.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_en">
                {{ add_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name_en"
                v-ltr
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_ar">
                {{ add_errors.name_ar.join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="add_data.name_ar"
                v-rtl
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>

          <div class="w-full space-y-3">
            <label
              for="module"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Module
            </label>
            <multiselect
              v-model="add_data.module_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="modules"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="modules.length === 0"
            ></multiselect>
            <span class="error">
              {{ (add_errors.module_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="region"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Region
            </label>
            <multiselect
              v-model="add_data.region_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="regions"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="regions.length === 0"
            ></multiselect>
            <span class="error">
              {{ (add_errors.region_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="city"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              City
            </label>
            <multiselect
              v-model="add_data.city_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="cities"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="cities.length === 0"
            ></multiselect>
            <span class="error">
              {{ (add_errors.city_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="district"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              District
            </label>
            <multiselect
              v-model="add_data.district_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="districts"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="districts.length === 0"
            ></multiselect>
            <span class="error">
              {{ (add_errors.district_id || []).join(" ") }}
            </span>
          </div>
          <figure class="space-y-4">
            <blockquote class="space-y-2">
              <slide-transition>
                <figcaption
                  v-if="add_data.commercial_register_image"
                  class="relative table"
                >
                  <img
                    :src="
                      add_data.commercial_register_image
                        ? convertImage(add_data.commercial_register_image)
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="commercial_register_image"
                    class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                  />
                  <button
                    class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                    type="button"
                    @click.prevent="
                      () =>
                        clearImage(
                          'add_data',
                          'commercial_register_image',
                          'uploader'
                        )
                    "
                  >
                    <i class="fa-solid fa-xmark fa-sm"></i>
                  </button>
                </figcaption>
              </slide-transition>
              <p class="text-gray-600 font-semibold rtl:font-bold text-sm">
                Commercial register image
              </p>
              <label class="block">
                <span class="sr-only">Choose commercial register image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="
                    (e) =>
                      $set(
                        add_data,
                        'commercial_register_image',
                        e.target.files[0]
                      )
                  "
                />
              </label>
              <p class="error" v-if="add_errors.commercial_register_image">
                {{ add_errors.commercial_register_image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <Map v-model="add_data.location" />
          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="'Update'"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <blockquote class="space-y-2">
              <slide-transition>
                <figcaption v-if="edit_data.logo" class="relative table">
                  <img
                    :src="
                      edit_data.logo
                        ? typeof edit_data.logo === 'object'
                          ? convertImage(edit_data.logo)
                          : edit_data.logo
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="logo"
                    class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                  />
                  <button
                    class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                    type="button"
                    @click.prevent="
                      () => clearImage('edit_data', 'logo', 'logoUploader')
                    "
                  >
                    <i class="fa-solid fa-xmark fa-sm"></i>
                  </button>
                </figcaption>
              </slide-transition>
              <p class="text-gray-600 font-semibold rtl:font-bold text-sm">
                Logo
              </p>
              <label class="block">
                <span class="sr-only">Choose Logo</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="logoUploader"
                  @change="(e) => $set(edit_data, 'logo', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="edit_errors.logo">
                {{ edit_errors.logo.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_en">
                {{ edit_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name_en"
                v-ltr
              />
              <label for="name_en">Name | en</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_ar">
                {{ edit_errors.name_ar.join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="edit_data.name_ar"
                v-rtl
              />
              <label for="name_ar">Name | ar</label>
            </div>
          </div>

          <div class="w-full space-y-3">
            <label
              for="module"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Module
            </label>
            <multiselect
              v-model="edit_data.module_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="modules"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="modules.length === 0"
            ></multiselect>
            <span class="error">
              {{ (edit_errors.module_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="region"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Region
            </label>
            <multiselect
              v-model="edit_data.region_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="regions"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="regions.length === 0"
            ></multiselect>
            <span class="error">
              {{ (edit_errors.region_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="city"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              City
            </label>
            <multiselect
              v-model="edit_data.city_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="cities"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="cities.length === 0"
            ></multiselect>
            <span class="error">
              {{ (edit_errors.city_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="district"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              District
            </label>
            <multiselect
              v-model="edit_data.district_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="districts"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="districts.length === 0"
            ></multiselect>
            <span class="error">
              {{ (edit_errors.district_id || []).join(" ") }}
            </span>
          </div>
          <figure class="space-y-4">
            <blockquote class="space-y-2">
              <slide-transition>
                <figcaption
                  v-if="edit_data.commercial_register_image"
                  class="relative table"
                >
                  <img
                    :src="
                      edit_data.commercial_register_image
                        ? typeof edit_data.commercial_register_image ===
                          'object'
                          ? convertImage(edit_data.commercial_register_image)
                          : edit_data.commercial_register_image
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="commercial_register_image"
                    class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                  />
                  <button
                    class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                    type="button"
                    @click.prevent="
                      () =>
                        clearImage(
                          'edit_data',
                          'commercial_register_image',
                          'uploader'
                        )
                    "
                  >
                    <i class="fa-solid fa-xmark fa-sm"></i>
                  </button>
                </figcaption>
              </slide-transition>
              <p class="text-gray-600 font-semibold rtl:font-bold text-sm">
                Commercial register image
              </p>
              <label class="block">
                <span class="sr-only">Choose commercial register image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="
                    (e) =>
                      $set(
                        edit_data,
                        'commercial_register_image',
                        e.target.files[0]
                      )
                  "
                />
              </label>
              <p class="error" v-if="edit_errors.commercial_register_image">
                {{ edit_errors.commercial_register_image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <Map v-model="edit_data.location" />
          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import Map from "@/components/basics/Map.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
export default {
  name: "Stores",
  data() {
    return {
      load: false,
      items: [],
      regions: [],
      cities: [],
      modules: [],
      districts: [],
      add_data: {
        region_id: null,
        district_id: null,
        city_id: null,
        location: {
          lat: 24.7135517,
          lng: 46.6752957,
        },
      },
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      status: ["all", "on", "off"],
      pagination: {
        status: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([
      await this.getModules(),
      await this.getRegions(),
      await this.getCities(),
      await this.getDistricts(),
      await this.getItems(),
    ]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        const uploader = this.$refs.uploader;
        this.add_data = {};
        this.add_errors = {};
        uploader.value = null;
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getRegions() {
      try {
        const { result } = await this.getRoute("regions");
        this.regions = result.regions;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getModules() {
      try {
        const { result } = await this.getRoute("modules");
        this.modules = result.modules;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getCities() {
      try {
        const { result } = await this.getRoute("cities");
        this.cities = result.cities;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getDistricts() {
      try {
        const { result } = await this.getRoute("districts");
        this.districts = result.districts;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "stores",
          options: {
            ...others,
            status: others.status ? others.status : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.stores;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(`stores/update_status/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`stores/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (item) {
            if (
              ["store_id", "region_id", "district_id", "city_id"].includes(key)
            ) {
              fd.append(key, item?.id);
            } else if (key === "logo") {
              if (item) {
                fd.append(key, item, item?.name);
              }
            } else if (key === "location") {
              for (const locationKey in item) {
                fd.append(locationKey, item[locationKey]);
              }
            } else {
              fd.append(key, item);
            }
          }
        }

        const { data } = await this.axios.post("stores/add", fd);
        this.add_data = {};
        this.add_active = false;
        this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};
        let fd = new FormData();

        for (const key in this.edit_data) {
          const item = this.edit_data[key];
          if (item) {
            if (
              ["store_id", "region_id", "district_id", "city_id"].includes(key)
            ) {
              fd.append(key, item?.id);
            } else if (key === "commercial_register_image") {
              if (item && typeof item === "object") {
                fd.append(key, item, item?.name);
              } else {
                fd.delete(key);
              }
            } else if (key === "location") {
              for (const locationKey in item) {
                fd.append(locationKey, item[locationKey]);
              }
            } else {
              fd.append(key, item);
            }
          }
        }

        const { data } = await this.axios.post("stores/update", fd);
        this.edit_data = {};
        this.edit_active = false;
        this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    clearImage(type, key, ref) {
      const uploader = this.$refs[ref];
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], type, null);
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        module_id: item?.module,
        region_id: item?.region,
        city_id: item?.city,
        district_id: item?.district,
        location: {
          lat: item.lat,
          lng: item.lng,
          address: item.address,
        },
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader, Map },
};
</script>
