export default {
  namespaced: true,
  state: {
    user_info: {},
    token: null,
    permissions: [],
  },
  getters: {
    getUser: (state) => state.user_info,
  },
  mutations: {
    SET_USER(state, { profile, token }) {
      state.user_info = profile || state.user_info;
      state.token = token;
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data;
    },
  },
  actions: {},
  modules: {},
};
