<template>
  <div class="p-6 space-y-6">
    <header class="grid">
      <tabs :link_type="'link'" :tabs="tabs" />
    </header>
    <slot></slot>
  </div>
</template>

<script>
import Tabs from "../basics/Tabs.vue";
export default {
  components: { Tabs },
  name: "Wrapper",
  data() {
    return {
      tabs: [
        {
          title: "Slides",
          url: "/site-appearance/slides",
        },
        {
          title: "Blog posts",
          url: "/site-appearance/blog-posts",
        },
        {
          title: "Frequently asked questions",
          url: "/site-appearance/faqs",
        },
        {
          title: "Pages",
          url: "/site-appearance/pages",
        },
      ],
    };
  },
};
</script>
