<template>
  <div>
    <wrapper>
      <section class="w-full space-y-6">
        <Table
          title="Blog posts"
          :records="`${pagination.total || 0} records`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          :has_search="false"
        >
          <template #actions>
            <div>
              <router-link
                to="/site-appearance/blog-posts/create"
                class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              >
                <i class="fa-solid fa-plus"></i>
                <span>New post</span>
              </router-link>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.status === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                  {{ item }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th>Title</th>
            <th>Status</th>

            <th>Latest update</th>
            <th>Actions</th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="4" v-for="i in 4" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.title || "-" }}</td>

              <td>
                <p class="status capitalize" :class="item_status[item.status]">
                  {{ item.status }}
                </p>
              </td>

              <td v-html="createTime(item.updated_at || item.created_at)"></td>

              <td>
                <div class="flex items-center gap-2">
                  <div>
                    <button
                      class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> Delete </span>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                      @click.prevent="changeStatus(item.id)"
                      :content="'Change status'"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <router-link
                      :to="`/site-appearance/blog-posts/${item.id}`"
                      class="w-8 h-8 bg-sky-600/10 text-sky-600 rounded flex items-center justify-center"
                      :content="'Update'"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <template #tbody v-else>
            <tr>
              <td colspan="4" class="!text-center">There are no posts</td>
            </tr>
          </template>
        </Table>
      </section>
    </wrapper>
  </div>
</template>
<script>
import Wrapper from "@/components/appearance/Wrapper.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { Wrapper },
  name: "SiteBlogPosts",
  data() {
    return {
      load: false,
      items: [],
      status: ["all", "on", "off"],
      pagination: {
        status: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "blog_posts",
          options: {
            ...others,
            status: others.status ? others.status : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.blog_posts;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `blog_posts/update_status/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`blog_posts/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
};
</script>
