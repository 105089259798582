<template>
  <div>
    <section class="w-full p-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
      <figure
        class="w-full ring-1 ring-gray-200 bg-white rounded lg:col-span-2 p-4"
      >
        <div id="chart">
          <apexchart
            type="line"
            height="350"
            :options="{
              ...chartOptions,

              ...globalOptions,
            }"
            :series="series"
          ></apexchart>
        </div>
      </figure>
      <figure class="w-full ring-1 ring-gray-200 bg-white rounded p-4">
        <div id="chartBar">
          <apexchart
            type="bar"
            height="350"
            :options="{
              ...barChart,

              ...globalOptions,
            }"
            :series="series"
          ></apexchart>
        </div>
      </figure>
      <figure class="w-full ring-1 ring-gray-200 bg-white rounded p-4">
        <div id="chartBar" v-if="loaded">
          <apexchart
            type="radialBar"
            height="350"
            :options="{
              ...radialBarChart,
              labels: Object.keys(redialSeries).map((e) =>
                e.replace(/_count/gi, '').toUpperCase()
              ),
              ...globalOptions,
            }"
            :series="Object.values(redialSeries)"
          ></apexchart>
        </div>
      </figure>
    </section>
    <Table
      title="Recent orders"
      :records="`${orders.length || 0} records`"
      :has_search="false"
      class="px-6"
    >
      <template #head>
        <th>#</th>
        <th>Customer</th>
        <th>Products</th>
        <th>Total</th>
        <th>Address</th>
        <th>Status</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!loaded">
        <TableLoad :rows="8" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="loaded && orders.length">
        <tr v-for="(item, index) in orders" :key="index">
          <td>
           #{{item.id}}
          </td>
           <td>
            <div class="flex-1 space-y-1">
              <p class="text-sm font-semibold rtl:font-bold">
                {{ item.customer_name || "-" }}
              </p>
              <a
                :href="`tel:${item.customer_mobile}`"
                class="text-xs font-medium rtl:font-semibold text-gray-600"
                v-if="item.customer_mobile"
              >
                {{ item.customer_mobile || "-" }}
              </a>
            </div>
          </td>

          <td>{{ item.products.length }}</td>
          <td>{{ item.total }} SAR</td>

         
          <td>
            <div class="flex-1 space-y-1">
              <p class="text-sm font-semibold rtl:font-bold">
                {{ item.shipping_address || "-" }}
              </p>
              <a
                :href="`#`"
                class="text-xs font-medium rtl:font-semibold text-gray-600"
              >
                {{
                  Object.keys(item)
                    .filter((e) => /shipping_(region|city|district)/gi.test(e))
                    .map((e) => (item[e] || {}).name)
                    .join(", ") || "-"
                }}
              </a>
            </div>
          </td>
          <td>
            <p class="status capitalize" :class="item_status[item.status]">
              {{ item.status }}
            </p>
          </td>
          <td v-html="createTime(item.updated_at)"></td>

          <td>
            <div class="actions-group">
              <div>
                <router-link
                  :to="`/orders/${item.id}`"
                  class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                >
                  <i class="fa-solid fa-eye"></i>
                </router-link>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="8" class="!text-center">There are no data display</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Analytics",
  data() {
    return {
      series: [
        {
          name: "Last week",
          data: [31, 40, 28, 51, 42, 109, 100, 50, 70, 80, 80, 10],
        },
        {
          name: "This week",
          data: [11, 32, 45, 32, 34, 52, 41, 70, 80, 10, 500, 50],
        },
      ],
      globalOptions: {
        dataLabels: {
          enabled: false,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].data[dataPointIndex];
          },
        },
        tooltip: {
          y: {
            formatter: function (val, { w }) {
              return val;
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Cairo",
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
          },
          axisBorder: {
            show: true,
            height: 1,
            width: "100%",
            offsetX: 0,
          },
          axisTicks: {
            show: false,
          },
        },
        theme: {
          palette: "palette8",
        },
      },
      chartOptions: {
        chart: {
          type: "line",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Sales",
          style: {
            fontSize: "24px",
            fontFamily: "Montserrat, Cairo",
            fontWeight: "bold",
          },
        },
        xaxis: {
          type: "category",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        // labels: series.monthDataSeries1.dates,
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            align: "left",
            rotate: -45,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
            offsetX: -5,
            offsetY: 10,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: "right",
          offsetY: 20,
          markers: {
            radius: 5,
            width: 10,
            height: 10,
          },
          itemMargin: {
            // horizontal: 10,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
              legend: {
                position: "bottom",
                offsetY: 0,

                itemMargin: {
                  horizontal: 10,
                  vertical: 0,
                },
              },
            },
          },
        ],
      },
      barChart: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Inventory",
          style: {
            fontSize: "24px",
            fontFamily: "Montserrat, Cairo",
            fontWeight: "bold",
          },
        },
        xaxis: {
          type: "category",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        // labels: series.monthDataSeries1.dates,
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            align: "left",
            rotate: -45,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
            offsetX: -5,
            offsetY: 10,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: "bottom",
          markers: {
            radius: 5,
            width: 10,
            height: 10,
          },
          itemMargin: {
            // horizontal: 10,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
              legend: {
                position: "bottom",
                offsetY: 0,

                itemMargin: {
                  horizontal: 10,
                  vertical: 0,
                },
              },
            },
          },
        ],
      },
      radialBarChart: {
        chart: {
          type: "radialbar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Total",
                formatter: function ({ config }) {
                  return config.series.reduce((a, b) => a + b, 0);
                },
              },
            },
          },
        },

        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
            },
          },
        ],
      },
      redialSeries: {},
      loaded: false,
      orders: [],
      item_status: {
        new: "bg-sky-500/10 text-sky-500",
        completed: "bg-gray-500/10 text-gray-500",
        preparing: "bg-yellow-500/10 text-yellow-500",
        ready: "bg-sky-700/10 text-sky-700",
        delivering: "bg-teal-500/10 text-teal-500",
        hold: "bg-gray-500/10 text-gray-500",
        cancelled: "bg-red-600/10 text-red-600",
        refunded: "bg-red-700/10 text-red-700",
      },
    };
  },
  async created() {
    Promise.all([await this.getItems(), await this.getOrders()]).finally(() => {
      this.loaded = true;
    });
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getItems() {
      try {
        const { result } = await this.getRoute("dashboard");
        // console.log(result);
        this.redialSeries = result;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getOrders() {
      try {
        const { result } = await this.getRoute("orders/recent");
        const { recent_orders } = result;
        this.orders = recent_orders;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        return require(`@/assets/images/cash.png`);
      }
    },
  },
};
</script>
