<template>
  <div>
    <wrapper>
      <section class="w-full space-y-6">
        <Table
          title="Slides"
          :records="`${pagination.total || 0} records`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          :has_search="false"
        >
          <template #actions>
            <div>
              <button
                class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                @click.prevent="add_active = !add_active"
              >
                <i class="fa-solid fa-plus"></i>
                <span>New slide</span>
              </button>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.status === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                  {{ item }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th></th>
            <th>Slide</th>
            <th>Title</th>
            <th>Status</th>
            <th>Latest update</th>
            <th>Actions</th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="6" v-for="i in 4" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length">
            <tr v-for="(item, index) in items" :key="index">
              <td>
                <div
                  class="grid gap-px rounded bg-gray-100 p-px max-w-fit overflow-hidden"
                  :class="index == 0 ? 'grid-cols-1' : 'grid-cols-2'"
                >
                  <button
                    class="flex items-center justify-center text-gray-600 hover:bg-sky-500 hover:text-white transition bg-white w-8 h-8"
                    v-if="index !== 0"
                    :disabled="move_disabled"
                    @click="MoveItem(index, index - 1)"
                    v-tippy
                    content="Move to top"
                  >
                    <i class="fa-solid fa-arrow-up fa-sm"></i>
                  </button>
                  <button
                    class="flex items-center justify-center text-gray-600 hover:bg-sky-500 hover:text-white transition bg-white w-8 h-8"
                    v-if="index !== items.length - 1"
                    :disabled="move_disabled"
                    @click="MoveItem(index, index + 1)"
                    v-tippy
                    content="Move to down"
                  >
                    <i class="fa-solid fa-arrow-down fa-sm"></i>
                  </button>
                </div>
              </td>
              <td>
                <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="item image"
                      class="w-full h-full rounded-md border object-cover border-gray-100"
                    />
                  </div>
                </div>
              </td>
              <td>{{ item.title || "-" }}</td>

              <td>
                <p class="status capitalize" :class="item_status[item.status]">
                  {{ item.status }}
                </p>
              </td>

              <td v-html="createTime(item.updated_at || item.created_at)"></td>

              <td>
                <div class="flex items-center gap-2">
                  <div>
                    <button
                      class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> Delete </span>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                      @click.prevent="changeStatus(item.id)"
                      :content="'Change status'"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded"
                      @click.prevent="showUpdate(item.id)"
                      :content="'Update'"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <template #tbody v-else>
            <tr>
              <td colspan="6" class="!text-center">
                There are no data to display
              </td>
            </tr>
          </template>
        </Table>
      </section>
    </wrapper>
    <Modal
      title="Add slide"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="add_data.image" class="relative table">
                <img
                  :src="
                    add_data.image
                      ? convertImage(add_data.image)
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="category image"
                  class="w-auto max-w-full object-contain"
                />
                <button
                  class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                  type="button"
                  @click.prevent="() => clearImage('add_data')"
                  v-if="typeof add_data.image === 'object'"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(add_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="add_errors.image">
                {{ add_errors.image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors['en.title']">
                {{ add_errors["en.title"].join(" ") }}
              </p>
              <input
                type="text"
                name="title_en"
                id="title_en"
                placeholder="Title"
                required
                autocomplete="off"
                v-model="add_data.en.title"
                v-ltr
              />
              <label for="title_en">Title | en</label>
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors['ar.title']">
                {{ add_errors["ar.title"].join(" ") }}
              </p>

              <input
                type="text"
                name="title_ar"
                id="title_ar"
                placeholder="االعنوان"
                required
                autocomplete="off"
                v-model="add_data.ar.title"
                v-rtl
              />
              <label for="title_ar">Title | ar</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors['en.content']">
                {{ add_errors["en.content"].join(" ") }}
              </p>
              <textarea
                name="content_en"
                id="content_en"
                placeholder="..."
                v-ltr
                v-model="add_data.en.content"
              ></textarea>
              <label for="content_en">Content | en</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors['ar.content']">
                {{ add_errors["ar.content"].join(" ") }}
              </p>

              <textarea
                name="content_ar"
                id="content_ar"
                placeholder="..."
                v-rtl
                v-model="add_data.ar.content"
              ></textarea>

              <label for="content_ar">Content | ar</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      title="Update"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="edit_data.image" class="relative table">
                <img
                  :src="
                    edit_data.image
                      ? typeof edit_data.image === 'object'
                        ? convertImage(edit_data.image)
                        : edit_data.image
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="category image"
                  class="w-auto max-w-full object-contain"
                />
                <button
                  class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                  type="button"
                  @click.prevent="() => clearImage('edit_data')"
                  v-if="typeof edit_data.image === 'object'"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(edit_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="edit_errors.image">
                {{ edit_errors.image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors['en.title']">
                {{ edit_errors["en.title"].join(" ") }}
              </p>
              <input
                type="text"
                name="title_en"
                id="title_en"
                placeholder="Title"
                required
                autocomplete="off"
                v-model="edit_data.en.title"
                v-ltr
              />
              <label for="title_en">Title | en</label>
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors['ar.title']">
                {{ edit_errors["ar.title"].join(" ") }}
              </p>

              <input
                type="text"
                name="title_ar"
                id="title_ar"
                placeholder="االعنوان"
                required
                autocomplete="off"
                v-model="edit_data.ar.title"
                v-rtl
              />
              <label for="title_ar">Title | ar</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors['en.content']">
                {{ edit_errors["en.content"].join(" ") }}
              </p>
              <textarea
                name="content_en"
                id="content_en"
                placeholder="..."
                v-ltr
                v-model="edit_data.en.content"
              ></textarea>
              <label for="content_en">Content | en</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors['ar.content']">
                {{ edit_errors["ar.content"].join(" ") }}
              </p>

              <textarea
                name="content_ar"
                id="content_ar"
                placeholder="..."
                v-rtl
                v-model="edit_data.ar.content"
              ></textarea>

              <label for="content_ar">Content | ar</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";

import Wrapper from "@/components/appearance/Wrapper.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { Wrapper, Modal, ButtonWithLoader },
  name: "SiteSlides",
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        en: {},
        ar: {},
      },
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      status: ["all", "on", "off"],
      pagination: {
        status: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      move_disabled: false,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "slides",
          options: {
            ...others,
            status: others.status ? others.status : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.slides;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(`slides/update_status/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`slides/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (item || typeof item === "number") {
            if (key === "image") {
              fd.append(key, item, item.name);
            } else if (["ar", "en"].includes(key)) {
              for (const translateKey in item) {
                fd.append(`${key}[${translateKey}]`, item[translateKey]);
              }
            } else {
              fd.append(key, item);
            }
          }
        }

        const { data } = await this.axios.post("slides/add", fd);
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.$refs.uploader.value = null;

        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};
        let fd = new FormData();

        for (const key in this.edit_data) {
          const item = this.edit_data[key];
          if (item || typeof item === "number") {
            if (key === "image") {
              if (typeof item !== "object") {
                fd.delete(key);
              } else {
                fd.append(key, item, item.name);
              }
            } else if (["ar", "en"].includes(key)) {
              for (const translateKey in item) {
                fd.append(`${key}[${translateKey}]`, item[translateKey]);
              }
            } else {
              fd.append(key, item);
            }
          }
        }
        const { data } = await this.axios.post("slides/update", fd);
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.$refs.uploader.value = null;

        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        ar: item.ar ? item.ar : {},
        en: item.en ? item.en : {},
      };
      this.edit_active = true;
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    async MoveItem(up, down) {
      try {
        this.move_disabled = true;

        const id_1 = this.items[up]?.id;
        const id_2 = this.items[down]?.id;

        if (down >= this.items.length) return;

        this.items.splice(down, 0, this.items.splice(up, 1)[0]);

        const { data } = await this.axios.post("slides/arrange", {
          item_1_id: id_1,
          item_2_id: id_2,
        });
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.move_disabled = false;
      }
    },
  },
};
</script>
