<template>
  <div>
    <container-wrapper :component-name="component" @change="changeTab">
      <component :is="component" />
    </container-wrapper>
  </div>
</template>
<script>
import PersonalAccount from "@/components/settings/PersonalAccount.vue";
import DiscountSettings from "@/components/settings/DiscountSettings.vue";
import ResetFactory from "@/components/settings/ResetFactory.vue";
import GeneralSettings from "@/components/settings/GeneralSettings.vue";
import BankAccounts from "@/components/settings/BankAccounts.vue";
import ContainerWrapper from "@/components/settings/ContainerWrapper.vue";
export default {
  components: {
    PersonalAccount,
    DiscountSettings,
    ResetFactory,
    ContainerWrapper,
    GeneralSettings,
    BankAccounts,
  },
  data() {
    return {
      tabs: {
        profile: "PersonalAccount",
        pages: "SitePages",
        "bank-accounts": "BankAccounts",
      },
    };
  },
  computed: {
    component() {
      const tab = this.$route.query?.tab;
      const currentTab = this.tabs[tab];
      if (!tab || !currentTab) {
        return "GeneralSettings";
      }
      return currentTab;
    },
  },
  methods: {
    changeTab(e) {
      const tab = Object.entries(this.tabs).find(([key, value]) => {
        if (value !== e) return null;
        return key;
      });

      if (tab) {
        const [tabName] = tab;
        this.$router.push({
          path: "/settings",
          query: {
            tab: tabName,
          },
        });
        return;
      }

      this.$router.push("/settings");
    },
  },
  name: "SettingsPage",
};
</script>
