<template>
  <div class="p-6 space-y-6">
    <cards-container :cards="cards" :load="!load"></cards-container>
    <Table
      title="Orders"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
      :has_export="true"
    >
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.status === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>#</th>
        <th>Customer</th>
        <th>Products</th>
        <th>Total</th>
        <th>Transactions</th>
        <th>Address</th>
        <th>Status</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="9" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
             <router-link
                  :to="`/orders/${item.id}`" class="text-sky-600">
            #{{item.id}}
            </router-link>
            </td>
          <td>
            <div class="flex-1 space-y-1">
              <p class="text-sm font-semibold rtl:font-bold">
                {{ item.customer_name || "-" }}
              </p>
              <a
                :href="`tel:${item.customer_mobile}`"
                class="text-xs font-medium rtl:font-semibold text-gray-600"
                v-if="item.customer_mobile"
              >
                {{ item.customer_mobile || "-" }}
              </a>
            </div>
          </td>

          <td>{{ item.products.length }}</td>
          <td>{{ item.total }} SAR</td>

          <td>
            <div class="actions-group" v-if="item.transactions.length">
              <div v-for="(payment, idx) in item.transactions" :key="idx">
                <p class="status bg-gray-200 text-gray-600">
                  {{ payment.payment_method }} ({{ payment.payment_option }})
                </p>
              </div>
            </div>
            <span v-else>N/A</span>
          </td>
          <td>
            <div class="flex-1 space-y-1">
              <p class="text-sm font-semibold rtl:font-bold">
                {{ item.shipping_address || "-" }}
              </p>
              <a
                :href="`#`"
                class="text-xs font-medium rtl:font-semibold text-gray-600"
              >
                {{
                  Object.keys(item)
                    .filter((e) => /shipping_(region|city|district)/gi.test(e))
                    .map((e) => (item[e] || {}).name)
                    .join(", ") || "-"
                }}
              </a>
            </div>
          </td>
          <td>
            <p class="status capitalize" :class="item_status[item.status]">
              {{ item.status }}
            </p>
          </td>
          <td v-html="createTime(item.updated_at)"></td>

          <td>
            <div class="actions-group">
              <div v-if="false">
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="closeItem(item.id)"
                  :disabled="disables[`close_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`close_${item.id}`]"
                  />
                  <span v-else> Close </span>
                </button>
              </div>
              <div>
                <router-link
                  :to="`/orders/${item.id}`"
                  class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                >
                  <i class="fa-solid fa-eye"></i>
                </router-link>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="9" class="!text-center">There are no data display</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import CardsContainer from "@/components/basics/CardsContainer.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Orders",
  components: {
    CardsContainer,
  },
  data() {
    return {
      items: [],
      load: false,
      cards: [
        {
          title: "Customers",
          key: "customers_count",
          icon: "fa-solid fa-user-group",
          value: 0,
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
        },
        {
          title: "Products",
          key: "products_count",
          icon: "fa-solid fa-shopping-bag",
          value: 0,
          style: {
            icon: "text-orange-500",
            parent: "bg-orange-500/20",
          },
        },
        {
          title: "Total",
          key: "total_count",
          icon: "fa-solid fa-dollar-sign",
          value: 0,
          style: {
            icon: "text-teal-500",
            parent: "bg-teal-500/20",
          },
        },
      ],
      item_status: {
        new: "bg-sky-500/10 text-sky-500",
        completed: "bg-gray-500/10 text-gray-500",
        preparing: "bg-yellow-500/10 text-yellow-500",
        ready: "bg-sky-700/10 text-sky-700",
        delivering: "bg-teal-500/10 text-teal-500",
        hold: "bg-gray-500/10 text-gray-500",
        cancelled: "bg-red-600/10 text-red-600",
        refunded: "bg-red-700/10 text-red-700",
      },
      // new,preparing'ready,delivering'hold,cancelled,refunded,completed
      status: [
        "all",
        "new",
        "preparing",
        "ready",
        "delivering",
        "cancelled",
        "completed",
      ],
      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
        status: null,
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "orders",
          options: {
            ...others,
            search_key: this.search,
          },
        });
        const { orders } = result;
        this.items = orders.data;
        this.$set(this.pagination, "total", orders.pagination.total);
        this.$set(
          this.pagination,
          "total_pages",
          orders.pagination.total_pages
        );
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async closeItem(id) {
      this.$set(this.disables, `close_${id}`, true);
      try {
        const { data } = await this.axios.post(`orders/close/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        return require(`@/assets/images/cash.png`);
      }
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
};
</script>
