<template>
  <section class="w-full p-6 space-y-6">
    <container-wrapper>
      <Table
        title="Inventory"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.status === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Product</th>
          <th>Price (SAR)</th>
          <th>SKU</th>
          <th>Barcode</th>
          <th>Qty</th>
          <th>Status</th>
          <th>Production date</th>
          <th>Expiry date</th>
          <th>Latest update</th>
          <th>Required quantity</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="11" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(product, index) in items" :key="index">
            <td>
              <div class="flex items-center gap-3">
                <div class="w-10 h-10">
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
                <span
                  class="inline-block line-clamp-2 max-w-sm whitespace-normal"
                >
                  {{ product["name_" + $i18n.locale] }}
                </span>
              </div>
            </td>

            <td>{{ product.price }}</td>

            <td>{{ product.sku || "-" }}</td>
            <td>{{ product.barcode || "-" }}</td>
            <td>{{ product.qty }}</td>
            <td>
              <p class="status capitalize" :class="item_status[product.status]">
                {{ product.status === 1 ? "Active" : "Not active" }}
              </p>
            </td>

            <td v-html="createTime(product.prod_date)"></td>
            <td v-html="createTime(product.exp_date)"></td>
            <td
              v-html="createTime(product.updated_at || product.created_at)"
            ></td>
            <td>
              <div class="flex items-center gap-2">
                <div>
                  <button
                    class="w-8 h-8 rounded bg-primary text-white"
                    @click="controlItemQty('increase', product.id)"
                  >
                    <i class="fa-solid fa-plus text-xs"></i>
                  </button>
                </div>
                <div>
                  <input
                    type="number"
                    name="proitem"
                    id="proitem"
                    class="w-14 text-xs pr-0 rtl:pl-0 rtl:pr-4 px-4 h-8 rounded bg-gray-100"
                    v-model="product.qty"
                  />
                </div>
                <div>
                  <button
                    class="w-8 h-8 rounded bg-primary text-white"
                    @click="controlItemQty('decrease', product.id)"
                  >
                    <i class="fa-solid fa-minus text-xs"></i>
                  </button>
                </div>
              </div>
            </td>
            <td>
              <div class="flex items-center gap-2">
                <div>
                  <router-link
                    :to="`/products/${product.id}`"
                    class="p-2 text-sm rounded bg-transparent block text-primary"
                  >
                    Edit
                  </router-link>
                </div>
                <div>
                  <button
                    class="p-2 text-sm rounded bg-primary/10 text-primary"
                    @click="addToCart(product.id)"
                  >
                    <i class="fa-solid fa-shopping-cart"></i>
                    Add to cart
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="11" class="!text-center">There are no products</td>
          </tr>
        </template>
      </Table>
    </container-wrapper>

    <Modal :close="false" @close="active = $event" class="!mt-0" v-if="active">
      <template>
        <div>
          <h1 class="font-bold text-lg text-gray-800 text-center mb-2">
            هل انت متاكد؟
          </h1>
          <p
            class="font-medium rtl:font-semibold text-lg text-gray-600 text-center"
          >
            اذا كنت تريد حذف تلك المنتج هذا يعني ان لم يتم عرض اي محتوى لتلك
            المنتج في متجرك ولن يظهر لعملاء وبالتالي سيتم حذف الطلب من سلات
            التسوق للعملاء.
          </p>
          <p
            class="font-medium rtl:font-semibold text-sm text-red-500 text-center mt-4"
            v-if="error"
          >
            {{ error }}
          </p>

          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-primary text-white font-medium rtl:font-semibold text-sm"
                :disabled="delete_disabled"
                @click.prevent="deleteItem"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="delete_disabled"
                ></i>
                حذف
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="
                  active = false;
                  product_id = null;
                "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import ContainerWrapper from "@/components/Inventory/ContainerWrapper.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Stock",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,

      status: ["all", "on", "off"],
      pagination: {
        status: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "products",
          options: {
            ...others,
            status: others.status ? others.status : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.products;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    addToCart(id) {
      let item = this.items.find((product) => product.id == id),
        cart = JSON.parse(localStorage.getItem("cart")) || [],
        isSame = cart.some((ca) => ca.id == id);
      if (isSame) {
        let product = cart.find((p) => p.id == id);
        Object.assign(product, {
          qty: product.qty + 1,
        });
        Object.assign(cart[cart.findIndex((i) => i.id == id)], product);
      } else {
        cart.push({
          ...item,
          qty: 1,
        });
      }
      localStorage.setItem("cart", JSON.stringify([...new Set(cart)]));
      this.$store.commit("SET_CART", JSON.parse(localStorage.getItem("cart")));
      this.createAlert("Product added to cart successfully");
      // this.createAlert(
      //
      //   this.$i18n.locale == "ar"
      //     ? "تم اضافة المنتج في السلة بنجاح"
      //     : "Product added to cart successfully"
      // );
    },
    controlItemQty(type, id) {
      let cart = JSON.parse(localStorage.getItem("cart")) || [],
        item = cart?.find((e) => e.id === id);
      if (item) {
        if (type === "increase") {
          this.$set(item, "qty", Number(item.qty) + 1);
        } else {
          this.$set(
            item,
            "qty",
            Number(item.qty) > 1 ? Number(item.qty) - 1 : 1
          );
        }
      }
      Object.assign(cart[cart.findIndex((i) => i.id == id)], item);
      localStorage.setItem("cart", JSON.stringify([...new Set(cart)]));
      this.$store.commit(
        "SET_CART",
        JSON.parse(localStorage.getItem("cart") || [])
      );
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ContainerWrapper },
};
</script>
