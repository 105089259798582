<template>
  <div>
    <container-wrapper>
      <figure
        class="w-full bg-white rounded-xl flex flex-col md:min-h-[calc(100vh-100px)]"
      >
        <div>
          <header class="w-full border-b border-gray-100 p-4">
            <div class="flex items-center">
              <div>
                <div
                  class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"
                ></div>
              </div>
              <div class="px-4 space-y-2 flex-1">
                <div
                  class="w-full max-w-[10rem] h-4 rounded bg-gray-200 animate-pulse"
                ></div>
                <div
                  class="w-full max-w-xs h-3 rounded bg-gray-200 animate-pulse"
                ></div>
              </div>
            </div>
          </header>
        </div>
        <div class="flex-1 p-4 overflow-y-auto">
          <div class="table m-auto py-20">
            <div class="flex items-center justify-center flex-col gap-6">
              <div>
                <div
                  class="w-28 h-28 flex items-center justify-center bg-gray-100 rounded-full text-gray-500"
                >
                  <i class="fa-solid fa-message fa-2xl"></i>
                </div>
              </div>
              <div class="flex-1 space-y-2 text-center">
                <h1 class="text-lg text-primary font-semibold rtl:font-bold">
                  There are no messages
                </h1>
                <p
                  class="text-sm text-gray-400 max-w-xl font-medium rtl:font-semibold"
                >
                  Click on the ticket from the other side if there are any
                  tickets available to complete the discussion and conversation.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <footer class="border-t border-t-gray-100 flex items-start gap-4 p-4">
            <div>
              <button class="w-8 h-8" @click="show_more = !show_more">
                <i class="fa-solid fa-plus fa-sm text-gray-600"></i>
              </button>
            </div>
            <div class="flex-1 space-y-3">
              <textarea
                name="message"
                id="message"
                placeholder="Type message"
                class="p-4 rounded bg-gray-100 resize-y text-xs w-full min-h-[3rem]"
              ></textarea>
              <slide-transition>
                <ul class="flex items-center gap-4 flex-wrap" v-if="show_more">
                  <li>
                    <label
                      for="images"
                      class="flex items-center justify-center gap-3 flex-col cursor-pointer"
                    >
                      <div
                        class="w-10 h-10 rounded-full ring-1 ring-gray-200 flex items-center justify-center text-gray-600"
                      >
                        <i class="fa-solid fa-camera"></i>
                      </div>
                      <p
                        class="text-xs font-semibold rtl:font-bold text-gray-600 text-center line-clamp-1"
                      >
                        Images
                      </p>
                      <input
                        type="file"
                        name="images"
                        id="images"
                        accept="image/*"
                        hidden
                        aria-hidden="true"
                        class="hidden"
                      />
                    </label>
                  </li>
                  <li>
                    <label
                      for="attachments"
                      class="flex items-center justify-center gap-3 flex-col cursor-pointer"
                    >
                      <div
                        class="w-10 h-10 rounded-full ring-1 ring-gray-200 flex items-center justify-center text-gray-600"
                      >
                        <i class="fa-solid fa-link"></i>
                      </div>
                      <p
                        class="text-xs font-semibold rtl:font-bold text-gray-600 text-center line-clamp-1"
                      >
                        Attachments
                      </p>
                      <input
                        type="file"
                        name="attachments"
                        id="attachments"
                        hidden
                        aria-hidden="true"
                        class="hidden"
                      />
                    </label>
                  </li>
                </ul>
              </slide-transition>
            </div>
            <div>
              <button
                class="w-8 h-8 text-white rounded-full bg-indigo-600 flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
                  />
                </svg>
              </button>
            </div>
          </footer>
        </div>
      </figure>
    </container-wrapper>
  </div>
</template>

<script>
import ContainerWrapper from "@/components/support/ContainerWrapper.vue";
import SlideTransition from "@/components/basics/SlideTransition.vue";
export default {
  components: { ContainerWrapper, SlideTransition },
  name: "SingleTicket",
  data() {
    return {
      show_more: false,
    };
  },
};
</script>
