<template>
  <div>
    <section class="w-full p-6 flex flex-col md:flex-row gap-4" v-if="load">
      <aside class="w-full lg:w-1/3 2xl:max-w-sm space-y-4">
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-3">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Invoice details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Total products
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.products.length }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Vat
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.vat }} SAR
                  </p>
                </div>
              </li>

              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Total
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.total }} SAR
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Subtotal
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.subtotal }} SAR
                  </p>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-3">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Customer details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Name
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.customer_name || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Mobile
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`tel:${item.customer_mobile}`"
                    v-if="item.customer_mobile"
                    class="text-sky-600 text-sm break-all"
                  >
                    {{ item.customer_mobile || "-" }}
                  </a>
                  <span v-else>-</span>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-3">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              User details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Name
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.user || {}).name || "-" }}
                  </p>
                </div>
              </li>

              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Mobile
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`tel:${(item.user || {}).mobile}`"
                    v-if="(item.user || {}).mobile"
                    class="text-sky-600 text-sm break-all"
                  >
                    {{ (item.user || {}).mobile || "-" }}
                  </a>
                  <span v-else>-</span>
                </div>
              </li>

              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Email
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`tel:${(item.user || {}).email}`"
                    v-if="(item.user || {}).email"
                    class="text-sky-600 text-sm break-all"
                  >
                    {{ (item.user || {}).email || "-" }}
                  </a>
                  <span v-else>-</span>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
      </aside>
      <main class="w-full lg:w-2/3 2xl:flex-1 space-y-4">
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-3">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Branch details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Name
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.branch || {})["name_" + $i18n.locale] || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Address
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`https://maps.google.com/?q=${item.branch.lat},${item.branch.lng}`"
                    class="text-sm text-sky-600 font-semibold rtl:font-bold"
                  >
                    {{ (item.branch || {}).address || "-" }}
                  </a>
                </div>
              </li>
              <li class="grid grid-cols-2 py-2">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Store
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.store || {})["name_" + $i18n.locale] || "-" }}
                  </p>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure
          class="w-full rounded bg-white ring-1 ring-gray-200"
          v-for="(product, index) in item.products"
          :key="index"
        >
          <blockquote class="flex flex-col sm:flex-row gap-6 p-4">
            <div>
              <div
                class="bg-gray-100 w-full max-w-[6rem] lg:max-w-[10rem] rounded"
              >
                <img
                  :src="
                    product.image
                      ? product.image
                      : require('@/assets/images/placeholder.png')
                  "
                  alt="product image"
                  class="max-w-full h-full rounded object-contain"
                />
              </div>
            </div>
            <div class="flex-1">
              <ul class="list-none space-y-1">
                <li>
                  <p class="text-primary font-semibold">
                    {{ product["name_" + $i18n.locale] }}
                  </p>
                </li>
                <li>
                  <p class="text-gray-600 font-medium text-sm line-clamp-2">
                    {{ product["desc_" + $i18n.locale] }}
                  </p>
                </li>
                <li>
                  <p class="text-gray-600 font-medium text-sm">
                    Quantity:
                    <span class="text-primary font-semibold">{{
                      product.qty
                    }}</span>
                  </p>
                </li>
                <li>
                  <p class="text-gray-600 font-medium text-sm">
                    Total:
                    <span class="text-primary font-semibold"
                      >{{ product.price }} SAR</span
                    >
                  </p>
                </li>
              </ul>
            </div>
          </blockquote>
        </figure>
      </main>
    </section>
    <section class="w-full p-6 flex flex-col md:flex-row gap-4" v-else>
      <aside class="w-full lg:w-1/3 2xl:max-w-sm space-y-4">
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-for="l in 3"
          :key="l"
        >
          <header class="p-4 border-b border-b-gray-100">
            <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
          </header>
          <blockquote class="p-4 space-y-4">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 py-2" v-for="i in 2" :key="i">
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
      </aside>
      <main class="w-full lg:w-2/3 2xl:flex-1 space-y-4">
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4 border-b border-b-gray-100">
            <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
          </header>
          <blockquote class="p-4 space-y-4">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 py-2" v-for="i in 2" :key="i">
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
      </main>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "InvoiceDetails",
  data() {
    return {
      item: {},
      load: false,
    };
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getItem() {
      try {
        const { result } = await this.getRoute(`invoices/${this.itemId}`);
        this.item = {
          ...result.invoice,
          store: result.invoice?.branch?.store,
        };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        // this.$router.push("/products");
      } finally {
        this.load = true;
      }
    },
  },
};
</script>
