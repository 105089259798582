<template>
  <div>
    <figure
      class="w-full flex flex-col text-center justify-center items-center gap-6 p-4"
    >
      <figcaption>
        <picture v-if="image">
          <img
            :src="image"
            class="w-full h-full max-w-[15rem] object-contain"
            alt="Empty image"
            title="Empty image"
          />
        </picture>
        <div
          v-if="icon"
          class="w-20 h-20 flex items-center justify-center bg-red-500/10 rounded-full text-red-500"
        >
          <i :class="icon"></i>
        </div>
      </figcaption>
      <blockquote class="space-y-4">
        <h1 class="text-lg font-bold" v-if="title">{{ title }}</h1>
        <p class="text-sm text-gray-600 font-semibold" v-if="content">
          {{ content }}
        </p>
        <router-link
          :to="link"
          v-if="link"
          class="text-primary text-sm font-semibold block text-center"
          >{{ linkText }}</router-link
        >
      </blockquote>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'EmptyCard',
  props: {
    image: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    },
  },
}
</script>
