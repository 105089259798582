<template>
  <section class="w-full p-6">
    <form
      class="w-full mx-auto space-y-6"
      @submit.prevent
      novalidate
      v-if="load"
    >
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">English content</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="form-group space-y-3 flex-1">
            <label for="title_en"> Title </label>
            <input
              type="text"
              name="title_en"
              id="title_en"
              v-model="add_data.en.title"
              placeholder="terms of use"
              v-ltr
            />
            <p class="error">
              {{ (add_errors["en.title"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="content_en"> Content </label>
            <editor
              v-model="add_data.en.content"
              id="content_en"
              placeholder="Type page content here"
            />
            <p class="error">
              {{ (add_errors["en.content"] || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Arabic content</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="form-group space-y-3 flex-1">
            <label for="title_ar"> Title </label>
            <input
              type="text"
              name="title_ar"
              id="title_ar"
              v-model="add_data.ar.title"
              placeholder="شروط الاستخدام"
              v-rtl
            />
            <p class="error">
              {{ (add_errors["ar.title"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="content_ar"> Content </label>
            <editor
              v-model="add_data.ar.content"
              id="content_ar"
              placeholder="اكتب محتوى الصفحة هنا"
            />
            <p class="error">
              {{ (add_errors["ar.content"] || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Seo details</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="form-group space-y-3 flex-1">
            <label for="seo_title"> Title </label>
            <input
              type="text"
              name="seo_title"
              id="seo_title"
              v-model="add_data.seo_title"
              placeholder="..."
            />
            <p class="error">
              {{ (add_errors["seo_title"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="seo_desc"> Description </label>
            <textarea v-model="add_data.seo_desc"></textarea>
            <p class="error">
              {{ (add_errors["seo_desc"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="seo_keywords"> Keywords </label>
            <VueTagsInput
              v-model="tag"
              :tags="add_data.seo_keywords"
              @tags-changed="(newTags) => (add_data.seo_keywords = newTags)"
              class="ti-container"
            />
            <p class="error">
              {{ (add_errors["seo_keywords"] || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
        :disabled="add_disabled"
        @click="addPage"
        >Save</button-with-loader
      >
    </form>
    <div class="w-full space-y-6" v-else>
      <figure
        class="w-full bg-white rounded ring-1 ring-gray-200"
        v-for="l in 2"
        :key="l"
      >
        <header class="p-4 border-b border-b-gray-100">
          <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
        </header>
        <blockquote class="p-4 space-y-4">
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            v-for="i in 2"
            :key="i"
          >
            <div class="w-full space-y-3">
              <div class="w-1/5 h-4 rounded bg-gray-200 animate-pulse"></div>
              <div class="w-full h-10 rounded bg-gray-200 animate-pulse"></div>
            </div>
          </div>
        </blockquote>
      </figure>
    </div>
  </section>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Editor from "@/components/basics/Editor.vue";
import { mapActions } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: { ButtonWithLoader, Editor, VueTagsInput },
  name: "CreatePage",
  data() {
    return {
      add_data: {
        ar: {},
        en: {},
      },
      add_errors: {},
      add_disabled: false,
      load: false,
      tag: "",
    };
  },
  computed: {
    pageId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getPage();
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getPage() {
      try {
        const { result } = await this.getRoute(`pages/${this.pageId}`);
        const item = result.page;
        this.add_data = {
          ...item,
          seo_keywords: item?.seo_keywords?.map((e) => ({ text: e })),
        };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        this.$router.push("/site-appearance/pages");
      } finally {
        this.load = true;
      }
    },
    async addPage() {
      this.add_disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      for (const key in this.add_data) {
        const item = this.add_data[key];
        if (item) {
          if (["ar", "en"].includes(key)) {
            for (const translateKey in item) {
              fd.append(`${key}[${translateKey}]`, item[translateKey]);
            }
          } else if (key === "seo_keywords") {
            for (const index in item) {
              fd.append("seo_keywords[" + index + "]", item[index].text);
            }
          } else {
            fd.append(key, item);
          }
        }
      }
      try {
        const { data } = await this.axios.post("pages/update", fd);
        this.$router.push("/site-appearance/pages");
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
  },
};
</script>
