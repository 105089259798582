<template>
  <div>
    <section class="w-full space-y-6 p-6">
      <header class="w-full">
        <div class="flex-1 relative">
          <input
            type="text"
            name="search"
            id="search"
            class="w-full bg-white rounded-md p-4 text-sm pl-10 rtl:pl-auto rtl:pr-10"
            placeholder="Search..."
          />
          <span
            class="absolute top-0 left-0 rtl:left-auto rtl:right-0 h-full w-10 flex items-center justify-center text-gray-500 text-sm"
          >
            <!-- <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i> -->
            <i class="fa-solid fa-search"></i>
          </span>
        </div>
      </header>
      <main class="space-y-4">
        <div v-if="!load" class="space-y-4">
          <figure v-for="l in 4" :key="l">
            <blockquote
              class="flex gap-4 items-center animate-pulse p-5 bg-white ring-1 ring-gray-200 rounded"
            >
              <div class="!self-start">
                <div class="w-9 h-9 rounded-full bg-gray-200"></div>
              </div>
              <div class="flex-1 space-y-2">
                <div class="w-1/3 h-4 rounded bg-gray-200"></div>
                <div class="w-2/3 h-3 rounded bg-gray-200 !mt-4"></div>
                <div class="w-full h-3 rounded bg-gray-200"></div>
              </div>
            </blockquote>
          </figure>
        </div>
        <EmptyCard
          title="Notifications are empty."
          content="There are no notifications to display here or your customers did not make any orders yet."
          icon="fa-solid fa-bell-slash fa-lg"
          v-else-if="load && !items.length"
        />
        <figure
          class="w-full rounded-xl bg-white"
          v-for="(item, index) in items"
          :key="index"
          v-else
        >
          <blockquote class="p-5 flex gap-4">
            <div>
              <div
                class="w-12 h-12 rounded-full bg-gray-200 text-gray-600 flex items-center justify-center"
              >
                <i class="fa-solid fa-fire-flame-curved"></i>
              </div>
            </div>
            <div class="space-y-2 flex-1">
              <h1 class="font-semibold rtl:font-bold">{{ item.subject }}</h1>
              <p class="text-sm font-medium rtl:font-semibold text-gray-600">
                {{ item.body }}
              </p>
              <p class="text-xs font-medium rtl:font-semibold text-gray-600">
                <!-- <a
                  href="http://appligates.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="underline text-primary"
                  >Appligates</a
                >
                •-->
                <span v-html="createTime(item.created_at)"></span>
              </p>
            </div>
          </blockquote>
        </figure>

        <div class="table mx-auto !py-6" v-if="pagination.total_pages > 1">
          <paginate
            v-model="pagination.page"
            :page-count="pagination.total_pages"
            :page-range="5"
            :margin-pages="2"
            :prev-text="'Previous'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Notifications",
  data() {
    return {
      load: false,
      items: [],
      pagination: {
        status: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
    };
  },
  async created() {
    await Promise.all([this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "notifications",
          options: {
            ...others,
            status: others.status ? others.status : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.notifications;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
  },
};
</script>
