<template>
  <div>
    <section
      class="w-full p-6 flex flex-col-reverse lg:flex-row gap-4"
      v-if="load"
    >
      <aside class="w-full lg:w-1/3 2xl:max-w-sm space-y-4">
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-4">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Customer details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Name
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.customer_name || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Mobile
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`tel:${item.customer_mobile}`"
                    class="text-sm text-primary font-semibold rtl:font-bold line-clamp-1"
                  >
                    {{ item.customer_mobile || "-" }}
                  </a>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    E-mail address
                  </p>
                </div>
                <div class="w-full">
                  <a
                    :href="`mailto:${item.customer_email}`"
                    class="text-sm text-primary font-semibold rtl:font-bold break-all"
                  >
                    {{ item.customer_email || "-" }}
                  </a>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-4">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Order details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Total products
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.products.length }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Vat
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.vat }} SAR
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Shipping fees
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.shipping_fees }} SAR
                  </p>
                </div>
              </li>

              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Total
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.total }} SAR
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Subtotal
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.sub_total }} SAR
                  </p>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-4">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Order details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 pt-3 pb-1.5 items-center">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Status
                  </p>
                </div>
                <div class="w-full">
                  <!-- <p class="status" :class="item_status[item.status]">
                    {{ item.status }}
                  </p> -->
                  <select
                    name="status"
                    id="status"
                    v-model="add_data.status"
                    class="custom-select text-sm"
                    @change.prevent="updateOrderStatus"
                    :disabled="disables[`status_${itemId}`]"
                  >
                    <option value="" selected disabled>-- select --</option>
                    <option
                      v-for="(status, index) in statuses"
                      :key="index"
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
              </li>
              <li
                class="space-y-4 pt-3 pb-1.5"
                v-for="(payment, index) in item.transactions"
                :key="index"
              >
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Payment status
                  </p>
                  <p
                    class="text-xs text-gray-600 font-semibold rtl:font-bold mt-1"
                  >
                    {{ payment.payment_method }} ({{ payment.payment_option }})
                  </p>
                </div>
                <div class="w-full">
                  <select
                    name="status"
                    id="status"
                    :value="payment.status"
                    class="custom-select text-sm"
                    @change.prevent="updateOrderPaymentStatus($, ievent, index)"
                    :disabled="disables[`payment_status_${index}_${itemId}`]"
                  >
                    <option value="" selected disabled>-- select --</option>
                    <option
                      v-for="(status, index) in payment_status"
                      :key="index"
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <div class="actions-group">
          <div v-if="false">
            <button
              class="py-2 px-4 rounded bg-primary text-white font-medium rtl:font-semibold text-center inline-flex gap-2 items-center text-sm"
              @click.prevent="downloadBill"
              :disabled="disables[`download_${itemId}`]"
            >
              <spinner
                size="w-4 h-4"
                class="inline-block align-middle"
                v-if="disables[`download_${itemId}`]"
              />
              <i class="fa-solid fa-receipt" v-else></i>
              <span>Download bill</span>
            </button>
          </div>
          <div>
            <button
              class="py-2 px-4 rounded bg-red-500/10 text-red-500 font-medium rtl:font-semibold text-center inline-flex gap-2 items-center text-sm"
              @click.prevent="cancelOrder"
              :disabled="disables[`cancelled_${itemId}`]"
            >
              <spinner
                size="w-4 h-4"
                class="inline-block align-middle"
                v-if="disables[`cancelled_${itemId}`]"
              />
              <i class="fa-solid fa-trash" v-else></i>
              <span>Cancel order</span>
            </button>
          </div>
        </div>
      </aside>
      <main class="w-full lg:w-2/3 2xl:flex-1 space-y-4">
        <header class="w-full grid">
          <div
            class="flex items-center justify-between gap-6 overflow-x-auto p-4 lg:p-0"
          >
            <div
              v-for="(track, index) in item.tracking"
              :key="index"
              class="flex-1"
            >
              <div class="space-y-2">
                <div
                  class="w-12 h-12 rounded-full flex items-center justify-center mx-auto"
                  :class="item_status[track.status]"
                >
                  <i :class="tracking_icon[track.status]"></i>
                </div>
                <div class="space-y-1 text-center whitespace-nowrap">
                  <p class="text-sm font-semibold rtl:font-bold capitalize">
                    {{ track.status }}
                  </p>
                  <p
                    class="text-xs font-medium rtl:font-semibold text-gray-600"
                  >
                    {{ track.created_at | moment("from") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
          <header class="border-b border-b-gray-100 p-4">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Shipping details
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Region
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.shipping_region || {}).name || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    City
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.shipping_city || {}).name || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    District
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ (item.shipping_district || {}).name || "-" }}
                  </p>
                </div>
              </li>
              <li class="grid grid-cols-2 pt-3 pb-1.5">
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    Address
                  </p>
                </div>
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ item.shipping_address || "-" }}
                  </p>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
          Products
        </p>
        <figure
          class="w-full rounded bg-white ring-1 ring-gray-200"
          v-for="(product, index) in item.products"
          :key="index"
        >
          <blockquote class="flex flex-col sm:flex-row gap-6 p-4">
            <div>
              <div
                class="bg-gray-100 w-full max-w-[6rem] lg:max-w-[10rem] rounded"
              >
                <img
                  :src="
                    product.image
                      ? product.image
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="product image"
                  class="max-w-full h-full rounded object-contain"
                />
              </div>
            </div>
            <div class="flex-1">
              <ul class="list-none space-y-1">
                <li>
                  <p class="text-primary font-semibold">
                    {{ product.name }}
                  </p>
                </li>

                <li>
                  <p class="text-gray-600 font-medium text-sm">
                    SKU:
                    <span class="text-primary font-semibold">{{
                      product.sku || "N/A"
                    }}</span>
                  </p>
                </li>
                <li>
                  <p class="text-gray-600 font-medium text-sm">
                    Quantity:
                    <span class="text-primary font-semibold">{{
                      product.qty || "N/A"
                    }}</span>
                  </p>
                </li>
                <li>
                  <p class="text-gray-600 font-medium text-sm">
                    Size:
                    <span class="text-primary font-semibold">{{
                      product.size || "N/A"
                    }}</span>
                  </p>
                </li>
                <li>
                  <p class="text-gray-600 font-medium text-sm">
                    Total:
                    <span class="text-primary font-semibold"
                      >{{ product.price || "N/A" }} SAR</span
                    >
                  </p>
                </li>
              </ul>
            </div>
          </blockquote>
        </figure>
      </main>
    </section>
    <section class="w-full p-6 flex flex-col md:flex-row gap-4" v-else>
      <aside class="w-full lg:w-1/3 2xl:max-w-sm space-y-4">
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-for="l in 3"
          :key="l"
        >
          <header class="p-4 border-b border-b-gray-100">
            <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
          </header>
          <blockquote class="p-4 space-y-4">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 pt-3 pb-1.5" v-for="i in 2" :key="i">
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
      </aside>
      <main class="w-full lg:w-2/3 2xl:flex-1 space-y-4">
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4 border-b border-b-gray-100">
            <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
          </header>
          <blockquote class="p-4 space-y-4">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li class="grid grid-cols-2 pt-3 pb-1.5" v-for="i in 2" :key="i">
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
                <div class="w-full">
                  <div
                    class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
      </main>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "InvoiceDetails",
  data() {
    return {
      item: {},
      load: false,
      item_status: {
        new: "bg-sky-500/10 text-sky-500",
        completed: "bg-gray-500/10 text-gray-500",
        preparing: "bg-yellow-500/10 text-yellow-500",
        ready: "bg-sky-700/10 text-sky-700",
        delivering: "bg-teal-500/10 text-teal-500",
        cancelled: "bg-red-600/10 text-red-600",
      },
      statuses: [
        "new",
        "preparing",
        "ready",
        "delivering",
        "cancelled",
        "completed",
      ],
      payment_status: ["pending", "rejected", "success"],
      add_data: {},
      disables: {},
      tracking_icon: {
        new: "fa-solid fa-bolt-lightning",
        preparing: "fa-solid fa-hourglass-end",
        ready: "fa-solid fa-check",
        delivering: "fa-solid fa-truck-ramp-box",
        cancelled: "fa-solid fa-ban",
        completed: "fa-solid fa-check-double",
      },
    };
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getItem() {
      try {
        const { result } = await this.getRoute(`orders/${this.itemId}`);
        const order = result.order;
        this.item = order;
        this.$set(this.add_data, "status", order.status);
        this.$set(this.add_data, "payment_status", order.payment_status);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        // this.$router.push("/products");
      } finally {
        this.load = true;
      }
    },
    async updateOrderStatus(e) {
      this.$set(this.disables, `status_${this.itemId}`, true);
      try {
        const { data } = await this.axios.post("orders/update_status", {
          status: e.target.value,
          id: this.itemId,
        });
        this.createAlert(data.message);
        this.getItem();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },

    async updateOrderPaymentStatus(e, i) {
      this.$set(this.disables, `payment_status_${i}_${this.itemId}`, true);
      try {
        const { data } = await this.axios.post("orders/update_payment_status", {
          status: e.target.value,
          id: this.itemId,
        });
        this.createAlert(data.message);
        this.getItem();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async cancelOrder() {
      this.$set(this.disables, `cancelled_${this.itemId}`, true);
      try {
        const { data } = await this.axios.post("orders/update_status", {
          status: "cancelled",
          id: this.itemId,
        });
        this.createAlert(data.message);
        this.getItem();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async downloadBill() {
      this.$set(this.disables, `download_${this.itemId}`, true);
      try {
        const { data } = await this.axios.get(
          `orders/download_waybill/${this.itemId}`,
          {
            responseType: "blob",
            // headers: {
            //   "Content-type": "blob",
            // },
          }
        );
        let blob = new Blob([data], {
          type: "application/pdf",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `waybill-${this.itemId}-${new Date().getTime()}.pdf`;
        link.click();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
  },
};
</script>
