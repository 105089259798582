import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import modules from "./modules";
import securePlugin from "./plugins/plugin";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    map_api_key: "AIzaSyBL9zlkm2YRyU_hN1yMGrVJ6_JlEHCzzig",
    BASE_URL:
      process.env.NODE_ENV != "production"
        ? "https://apis-matjari.brmaja.host/admin"
        : "https://apis-matjari.brmaja.host/admin",
  },
  mutations: {},

  modules: { ...modules },
  plugins: [securePlugin],
  getters: {},
  actions: {},
});
