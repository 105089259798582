<template>
  <div class="p-6 space-y-6">
    <Table
      title="Incomes"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div>
          <p class="text-lg font-semibold rtl:font-bold">Total: 0.00</p>
        </div>
      </template>

      <template #head>
        <th>Order no.</th>
        <th>Payment method</th>
        <th>Products amount</th>
        <th>Total</th>
        <th>Delivery fees</th>
        <th>Latest update</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="6" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.order_id }}</td>
          <td>
            <div class="flex items-center gap-2">
              <div v-if="item.payment_option">
                <img
                  :src="getPaymentImage(item.payment_option)"
                  alt="payment option"
                  width="20"
                  height="15"
                />
              </div>
              <div>
                {{ item.payment_option }}
              </div>
            </div>
          </td>
          <td>{{ item.products_total || 0 }}</td>
          <td>{{ item.total || 0 }}</td>
          <td>{{ item.delivery_fees || 0 }}</td>

          <td v-html="createTime(item.updated_at)"></td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="6" class="!text-center">There are no data display</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Incomes",

  data() {
    return {
      items: [],
      load: false,

      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
        status: null,
      },
    };
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "incomes",
          options: {
            ...others,
            search_key: this.search,
          },
        });
        const { incomes, pagination } = result;
        this.items = incomes.data;
        this.$set(this.pagination, "total", pagination?.total);
        this.$set(this.pagination, "total_pages", pagination?.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },

    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        return require(`@/assets/images/cash.png`);
      }
    },
  },
};
</script>
