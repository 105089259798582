import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/account/login.vue";
import Analytics from "@/views/Analytics.vue";
import PageNotFound from "@/views/access/PageNotFound.vue";
import PermissionsAllowed from "@/views/access/PermissionsAllowed.vue";
import Notifications from "@/views/Notifications.vue";
import Support from "@/views/support/index.vue";
import SingleTicket from "@/views/support/_id.vue";
import Stores from "@/views/stores/index.vue";
import Modules from "@/views/Modules.vue";
import Categories from "@/views/Categories.vue";
import Brands from "@/views/Brands.vue";
import Coupons from "@/views/Coupons.vue";
import Customers from "@/views/Customers.vue";
import Regions from "@/views/locations/Regions.vue";
import Cities from "@/views/locations/Cities.vue";
import Districts from "@/views/locations/Districts.vue";
import Packages from "@/views/packages/index.vue";
import CreatePackage from "@/views/packages/create.vue";
import UpdatePackage from "@/views/packages/update.vue";
import Slides from "@/views/appearance/Slides.vue";
import Pages from "@/views/appearance/pages/index.vue";
import CreatePage from "@/views/appearance/pages/create.vue";
import UpdatePage from "@/views/appearance/pages/update.vue";
import Blogs from "@/views/appearance/blogs/index.vue";
import CreatePost from "@/views/appearance/blogs/create.vue";
import UpdatePost from "@/views/appearance/blogs/update.vue";
import FAQs from "@/views/appearance/FAQs.vue";
import NotificationTemplates from "@/views/NotificationTemplates.vue";
import EmailTemplates from "@/views/EmailTemplates.vue";
import Incomes from "@/views/Incomes.vue";
import Sizes from "@/views/options/Sizes.vue";
// import SingleSupplier from "@/views/suppliers/_id.vue";

// customers
import Employees from "@/views/Employees.vue";
import ProductsPage from "@/views/products/index.vue";
import CreateProduct from "@/views/products/Create.vue";
import UpdateProduct from "@/views/products/Update.vue";
import StockPage from "@/views/inventory/Stock.vue";
import NearExpiry from "@/views/inventory/NearExpiry.vue";
import Expired from "@/views/inventory/Expired.vue";
import Inventory from "@/views/inventory/index.vue";
import Invoices from "@/views/invoices/index.vue";
import InvoiceDetails from "@/views/invoices/_id.vue";
import Orders from "@/views/orders/index.vue";
import OrderDetails from "@/views/orders/_id.vue";

import RateTypes from "@/views/Rates.vue";
// settings
import SettingsPage from "@/views/Settings.vue";
Vue.use(VueRouter);
// middleware & store
import store from "@/store";
import auth from "@/middlewares/auth";

const routes = [
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/",
    name: "Analytics",
    component: Analytics,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/packages",
    name: "Packages",
    component: Packages,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/packages/create",
    name: "CreatePackage",
    component: CreatePackage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/packages/:id",
    name: "UpdatePackage",
    component: UpdatePackage,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/site-appearance/slides",
    name: "Slides",
    component: Slides,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/site-appearance/pages",
    name: "Pages",
    component: Pages,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/site-appearance/pages/create",
    name: "CreatePage",
    component: CreatePage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/site-appearance/pages/:id",
    name: "UpdatePage",
    component: UpdatePage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/site-appearance/blog-posts",
    name: "Blogs",
    component: Blogs,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/site-appearance/blog-posts/create",
    name: "CreatePost",
    component: CreatePost,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/site-appearance/blog-posts/:id",
    name: "UpdatePost",
    component: UpdatePost,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/site-appearance/faqs",
    name: "FAQs",
    component: FAQs,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/notification-templates",
    name: "NotificationTemplates",
    component: NotificationTemplates,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/email-templates",
    name: "EmailTemplates",
    component: EmailTemplates,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/incomes",
    name: "Incomes",
    component: Incomes,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/products",
    name: "ProductsPage",
    component: ProductsPage,
    meta: {
      middleware: [auth],
      endpoint_name: "products",
    },
  },
  {
    path: "/products/create",
    name: "CreateProduct",
    component: CreateProduct,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/products/:id",
    name: "UpdateProduct",
    component: UpdateProduct,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory/near-expiry",
    name: "NearExpiry",
    component: NearExpiry,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory/expired",
    name: "Expired",
    component: Expired,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory/stock",
    name: "StockPage",
    component: StockPage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/invoices/products",
  //   name: "ProductsInvoices",
  //   component: ProductsInvoices,
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/invoices/:id",
    name: "InvoiceDetails",
    component: InvoiceDetails,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      middleware: [auth],
      endpoint_name: "orders",
    },
  },

  {
    path: "/orders/:id",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/support/:id",
    name: "SingleTicket",
    component: SingleTicket,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/stores",
    name: "Stores",
    component: Stores,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/modules",
    name: "Modules",
    component: Modules,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/brands",
    name: "Brands",
    component: Brands,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/sizes",
    name: "Sizes",
    component: Sizes,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/locations/regions",
    name: "Regions",
    component: Regions,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/locations/cities",
    name: "Cities",
    component: Cities,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/locations/districts",
    name: "Districts",
    component: Districts,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/rates",
    name: "RateTypes",
    component: RateTypes,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: SettingsPage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/not-allowed",
    name: "PermissionsAllowed",
    component: PermissionsAllowed,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      to,
      store,
      $cookies,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (store.state.token || Vue.$cookies.get("token")) {
    if (to.name === "Auth") {
      return next("/");
    }
  }
  return next();
});
export default router;
