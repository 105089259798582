<template>
  <div class="p-6">
    <section class="w-full" v-if="load">
      <form class="w-full mx-auto space-y-6" @submit.prevent novalidate>
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Basic details</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="name_ar"> Name | Ar </label>
                  <input
                    type="text"
                    name="name_ar"
                    id="name_ar"
                    v-model="add_data.name_ar"
                    placeholder="الاسم"
                    v-rtl
                  />
                  <p class="error">
                    {{ (add_errors["name_ar"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="name_en"> Name | En </label>
                  <input
                    type="text"
                    name="name_en"
                    id="name_en"
                    v-model="add_data.name_en"
                    placeholder="Name"
                    v-ltr
                  />
                  <p class="error">
                    {{ (add_errors["name_en"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="desc_ar"> Description | Ar </label>
                  <textarea
                    name="desc_ar"
                    id="desc_ar"
                    placeholder="..."
                    v-rtl
                    v-model="add_data.desc_ar"
                  ></textarea>
                  <p class="error">
                    {{ (add_errors["desc_ar"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="desc_en"> Description | En </label>
                  <textarea
                    name="desc_en"
                    id="desc_en"
                    placeholder="..."
                    v-ltr
                    v-model="add_data.desc_en"
                  ></textarea>
                  <p class="error">
                    {{ (add_errors["desc_en"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="months"> Months </label>
                  <input
                    type="number"
                    name="months"
                    id="months"
                    v-model="add_data.months"
                    placeholder="1"
                    min="1"
                  />
                  <p class="error">
                    {{ (add_errors["months"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="trial_days"> Trial days </label>
                  <input
                    type="number"
                    name="trial_days"
                    id="trial_days"
                    v-model="add_data.trial_days"
                    placeholder="1"
                    min="1"
                  />
                  <p class="error">
                    {{ (add_errors["trial_days"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="price"> Price </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    v-model="add_data.price"
                    placeholder="1"
                    min="1"
                  />
                  <p class="error">
                    {{ (add_errors["price"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="alt_price"> Alt price </label>
                  <input
                    type="number"
                    name="alt_price"
                    id="alt_price"
                    v-model="add_data.alt_price"
                    placeholder="1"
                    min="1"
                  />
                  <p class="error">
                    {{ (add_errors["alt_price"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group space-y-3 flex-1">
              <label for="users_limit"> Users limit </label>
              <input
                type="number"
                name="users_limit"
                id="users_limit"
                v-model="add_data.users_limit"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (add_errors["users_limit"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3 flex-1">
              <label for="batches"> Batches </label>
              <input
                type="number"
                name="batches"
                id="batches"
                v-model="add_data.batches"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (add_errors["batches"] || []).join(" ") }}
              </p>
            </div>
            <ul class="space-y-4">
              <li class="checkbox">
                <input
                  type="checkbox"
                  name="portal_login"
                  id="portal_login"
                  :true-value="1"
                  :false-value="0"
                  v-model="add_data.portal_login"
                />
                <label
                  for="portal_login"
                  class="text-sm font-medium rtl:font-semibold capitalize"
                  >Portal login</label
                >
              </li>
              <li class="checkbox">
                <input
                  type="checkbox"
                  name="has_device"
                  id="has_device"
                  :true-value="1"
                  :false-value="0"
                  v-model="add_data.has_device"
                />
                <label
                  for="has_device"
                  class="text-sm font-medium rtl:font-semibold capitalize"
                  >Has device</label
                >
              </li>
              <li class="checkbox">
                <input
                  type="checkbox"
                  name="has_discount"
                  id="has_discount"
                  :true-value="1"
                  :false-value="0"
                  v-model="add_data.has_discount"
                />
                <label
                  for="has_discount"
                  class="text-sm font-medium rtl:font-semibold capitalize"
                  >Has discount</label
                >
              </li>
            </ul>
          </blockquote>
        </figure>
        <slide-transition>
          <figure
            class="w-full bg-white rounded ring-1 ring-gray-200"
            v-show="!!add_data.has_discount"
          >
            <header class="p-4">
              <p class="text-base font-semibold rtl:font-bold">Discount</p>
            </header>
            <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
              <div class="w-full">
                <div class="w-full flex gap-4 flex-col sm:flex-row">
                  <div class="form-group space-y-3 flex-1">
                    <label for="discount_type"> Discount type </label>
                    <select
                      name="discount_type"
                      id="discount_type"
                      v-model="add_data.discount_type"
                      class="custom-select"
                    >
                      <option value="" selected disabled>- select --</option>
                      <option value="fixed">Fixed</option>
                      <option value="percentage">Percentage</option>
                    </select>
                    <p class="error">
                      {{ (add_errors["discount_type"] || []).join(" ") }}
                    </p>
                  </div>
                  <div class="form-group space-y-3 flex-1">
                    <label for="discount_value"> Discount value </label>
                    <input
                      type="number"
                      name="discount_value"
                      id="discount_value"
                      v-model="add_data.discount_value"
                      placeholder="1"
                      min="1"
                    />
                    <p class="error">
                      {{ (add_errors["discount_value"] || []).join(" ") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <div class="w-full flex gap-4 flex-col sm:flex-row">
                  <div class="flex-1 form-group space-y-3">
                    <label for="discount_start_date"> Start at </label>
                    <t-datepicker
                      placeholder="xxxx-xx-xx"
                      v-model="add_data.discount_start_date"
                      :clearable="false"
                      id="discount_start_date"
                      userFormat="Y-m-d"
                      :weekStart="6"
                    />
                    <p class="error">
                      {{ (add_errors.discount_start_date || []).join(" ") }}
                    </p>
                  </div>
                  <div class="flex-1 form-group space-y-3">
                    <label for="discount_end_date"> End at </label>
                    <t-datepicker
                      placeholder="xxxx-xx-xx"
                      v-model="add_data.discount_end_date"
                      :clearable="false"
                      id="discount_end_date"
                      userFormat="Y-m-d"
                      :weekStart="6"
                      :minDate="add_data.discount_start_date"
                    />
                    <p class="error">
                      {{ (add_errors.discount_end_date || []).join(" ") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="discount_used_limit"> Discount used limit </label>
                <input
                  type="number"
                  step="any"
                  name="discount_used_limit"
                  id="discount_used_limit"
                  v-model="add_data.discount_used_limit"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["discount_used_limit"] || []).join(" ") }}
                </p>
              </div>
            </blockquote>
          </figure>
        </slide-transition>

        <button-with-loader
          btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
          :disabled="add_disabled"
          @click="updatePackage"
          >Save</button-with-loader
        >
      </form>
    </section>
    <div class="w-full space-y-6" v-else>
      <figure
        class="w-full bg-white rounded ring-1 ring-gray-200"
        v-for="l in 2"
        :key="l"
      >
        <header class="p-4 border-b border-b-gray-100">
          <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
        </header>
        <blockquote class="p-4 space-y-4">
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            v-for="i in 2"
            :key="i"
          >
            <div class="w-full space-y-3">
              <div class="w-1/5 h-4 rounded bg-gray-200 animate-pulse"></div>
              <div class="w-full h-10 rounded bg-gray-200 animate-pulse"></div>
            </div>
          </div>
        </blockquote>
      </figure>
    </div>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions } from "vuex";
export default {
  components: { ButtonWithLoader },
  name: "UpdatePackage",
  data() {
    return {
      load: false,
      add_data: {
        discount_type: "",
      },
      add_errors: {},
      add_disabled: false,
    };
  },
  created() {
    this.getPackage();
  },
  watch: {
    "add_data.has_discount"() {
      this.clearDiscountValues();
    },
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getPackage() {
      try {
        const { result } = await this.getRoute({
          name: "packages",
        });
        this.add_data = result.packages.find(
          (e) => e.id === parseInt(this.itemId)
        );
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        this.$router.push("/packages");
      } finally {
        this.load = true;
      }
    },
    async updatePackage() {
      this.add_disabled = true;
      this.add_errors = {};
      if (!this.add_data?.has_discount) {
        await this.clearDiscountValues();
      }
      try {
        const { data } = await this.axios.post(
          "packages/update",
          this.add_data
        );
        this.$router.push("/packages");
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    clearDiscountValues() {
      return new Promise((resolve) => {
        for (const key in this.add_data) {
          if (/discount_\w+/gi.test(key)) {
            delete this.add_data[key];
          }
        }
        resolve();
      });
    },
  },
};
</script>
