<template>
  <div>
    <div
      id="app"
      class="min-h-screen"
      :class="{
        'xl:flex flex-row rtl:flex-row-reverse justify-between': hasToken,
      }"
    >
      <!-- <Header
      v-if="
        hasToken &&
        !['Login', 'Register', 'ForgotPassword', 'ResetPassword'].includes(
          $route.name
        )
      "
      :active="active"
      @close="active = $event"
    /> -->
      <side-bar
        v-if="
          hasToken &&
          !['Login', 'Register', 'ForgotPassword', 'ResetPassword'].includes(
            $route.name
          )
        "
        :active="active"
        ref="sideBar"
        @close="active = $event"
      />
      <div
        class="flex-1 sidebar-move"
        :class="{
          'pb-24': ![
            'Login',
            'Register',
            'ForgotPassword',
            'ResetPassword',
          ].includes($route.name),
        }"
      >
        <nav
          class="w-full p-4 bg-white border-b border-b-gray-200 sticky top-0 z-[5] xl:z-20"
          v-if="hasToken"
        >
          <ul class="list-none flex items-center gap-2">
            <li class="flex-1 sm:flex-[0] xl:hidden">
              <button
                @click.prevent="openCloseSideBar"
                type="button"
                class="w-9 h-9 rounded-full bg-white flex items-center justify-center text-gray-600"
              >
                <i class="fa-solid fa-bars fa-md"></i>
              </button>
            </li>
            <li class="flex-1 hidden sm:block">
              <div class="flex items-center gap-3">
                <!-- <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        user.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="profile image"
                      class="w-full h-full object-cover rounded-full border border-gray-100"
                    />
                  </div>
                </div> -->
                <div class="flex-1">
                  <div class="table">
                    <h3
                      class="text-sm text-gray-800 font-semibold rtl:font-bold line-clamp-1"
                    >
                      {{ user.name }}
                    </h3>
                    <a
                      :href="`mailto:${user.email}`"
                      class="text-xs text-gray-400 font-medium rtl:font-semibold line-clamp-1 mt-0.5"
                    >
                      {{ user.email }}
                    </a>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <router-link
                to="/notifications"
                class="w-9 h-9 rounded-full bg-white flex items-center justify-center text-gray-600 ring-1 ring-gray-100"
                v-tippy
                content="Notifications"
              >
                <span class="relative">
                  <i class="fa-solid fa-bell fa-md"></i>
                  <span
                    class="absolute -top-0.5 -right-1 rtl:-left-1 rtl:right-auto w-2 h-2 rounded-full bg-red-500 ring-2 ring-white"
                  >
                    <span
                      class="w-full h-full rounded-full bg-red-500/50 animate-ping absolute inset-0"
                    >
                    </span>
                  </span>
                </span>
              </router-link>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click.prevent="logout"
                v-tippy
                content="Logout"
                class="w-9 h-9 rounded-full bg-white flex items-center justify-center text-gray-600 ring-1 ring-gray-100"
              >
                <i class="fa-solid fa-arrow-right-from-bracket fa-md"></i>
              </a>
            </li>
          </ul>
        </nav>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
import ButtonWithLoader from "./components/basics/ButtonWithLoader.vue";
import SideBar from "@/components/layouts/SideBar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Header,
    Footer,
    ButtonWithLoader,
    SideBar,
  },
  data() {
    return {
      active: false,
      add_disabled: false,
      add_data: {
        track_type: "open",
        branch_id: null,
      },
      add_errors: {},
      branches: [],
    };
  },
  watch: {
    "$route.name"() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
    ...mapGetters("root", ["enabled_notifications"]),

    hasToken() {
      const token = this.$store.state.auth.token;
      return !!token;
    },
  },
  async created() {
    // if (!this.enabled_notifications) {
    //   this.checkPermissions({
    //     title: "Hi there!",
    //     body: "The notification has been successfully accessed",
    //   });
    // }
    // get branches
  },
  methods: {
    openWaPopup() {
      document.querySelector(".wa-box").classList.toggle("hidden");
    },
    openBar() {
      this.active = true;
    },

    openCloseSideBar() {
      const sidebar = this.$refs.sideBar?.$el;
      if (!sidebar) return;
      if (sidebar.classList.contains("opacity-0")) {
        sidebar.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        sidebar.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
  },
};
</script>
