<template>
  <section class="w-full p-8">
    <main class="w-full max-w-screen-lg mx-auto text-center">
      <img
        src="@/assets/images/error.png"
        alt="permission image"
        class="w-full max-w-xs mx-auto mb-6"
      />
      <h1 class="text-2xl font-bold text-center text-primary mb-4">
        لا يمكن الوصول لتلك الصفحة.
      </h1>
      <p class="font-medium rtl:font-semibold text-gray-600 text-lg mb-10">
        تعذر العثور على الصفحة التي تبحث عنها ، ربما تمت إزالتها أو إعادة
        تسميتها أو لم تكن موجودة في المقام الأول. أو حدث خطأ آخر ، يرجى المحاولة
        مرة أخرى لاحقًا
      </p>
      <router-link
        to="/"
        class="py-2 px-6 rounded-full bg-blue-500 text-white text-sm mb-3 inline-block mx-1 font-medium rtl:font-semibold"
      >
        العودة للرئيسية
      </router-link>
    </main>
  </section>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
