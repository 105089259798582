<template>
  <div>
    <section class="w-full p-6 space-y-6">
      <Table
        title="Customers"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div>
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>New customer</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.status === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>Name</th>
          <th>E-mail address</th>

          <th>Status</th>
          <th>Latest update</th>
          <th>Actions</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="flex items-center gap-3">
                <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        item.photo || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="product image"
                      class="w-full h-full rounded-md border object-cover border-gray-100"
                    />
                  </div>
                </div>
                <div class="space-y-1 flex-1">
                  <p class="inline-block line-clamp-1">
                    {{ item.name }}
                  </p>
                  <a
                    :href="`tel:${item.mobile}`"
                    class="text-sky-600 text-sm line-clamp-1"
                    >{{ item.mobile }}</a
                  >
                </div>
              </div>
            </td>
            <td>
              <a :href="`mailto:${item.email}`" class="text-sky-600 text-sm">{{
                item.email
              }}</a>
            </td>

            <td>
              <p class="status capitalize" :class="item_status[item.status]">
                {{ item.status }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div>
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="'Change status'"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="'Update'"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="5" class="!text-center">There are no data display</td>
          </tr>
        </template>
      </Table>
    </section>
    <Modal
      title="Add customer"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="add_data.photo" class="relative table">
                <img
                  :src="
                    add_data.photo
                      ? convertImage(add_data.photo)
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="category image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                  type="button"
                  @click.prevent="() => clearImage('add_data')"
                  v-if="typeof add_data.photo === 'object'"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(add_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="add_errors.photo">
                {{ add_errors.photo.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name">
                {{ add_errors.name.join(" ") }}
              </p>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name"
              />
              <label for="name">Name </label>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="email"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              E-mail address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
              autocomplete="off"
              v-model="add_data.email"
            />
            <span class="error">
              {{ (add_errors.email || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="mobile"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Mobile number
            </label>
            <vue-tel-input
              id="mobile"
              ref="customerMobile"
              v-model="add_data.mobile"
              @open="handleOpen('customerMobile')"
            ></vue-tel-input>
            <span class="error">
              {{ (add_errors.mobile || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="password"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Password
            </label>
            <div class="relative">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
                autocomplete="off"
                v-model="add_data.password"
              />
              <button
                class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                type="button"
                @click.prevent="textPasswordType"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>

            <span class="error">
              {{ (add_errors.password || []).join(" ") }}
            </span>
          </div>
          <div class="space-y-2">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Gender
            </p>
            <ul class="space-y-4">
              <li class="checkbox">
                <input
                  type="radio"
                  name="genderGroup"
                  id="genderMale"
                  value="male"
                  v-model="add_data.gender"
                />
                <label
                  for="genderMale"
                  class="text-sm font-medium rtl:font-semibold capitalize"
                  >Male</label
                >
              </li>
              <li class="checkbox">
                <input
                  type="radio"
                  name="genderGroup"
                  id="genderFemale"
                  value="female"
                  v-model="add_data.gender"
                />
                <label
                  for="genderFemale"
                  class="text-sm font-medium rtl:font-semibold capitalize"
                  >Female</label
                >
              </li>
            </ul>
            <p class="error">
              {{ (add_errors.gender || []).join(" ") }}
            </p>
          </div>
          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Add
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="'Update'"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="edit_data.photo" class="relative table">
                <img
                  :src="
                    edit_data.photo
                      ? typeof edit_data.photo === 'object'
                        ? convertImage(edit_data.photo)
                        : edit_data.photo
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="category image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                  type="button"
                  @click.prevent="() => clearImage('edit_data')"
                  v-if="typeof edit_data.photo === 'object'"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(edit_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="edit_errors.photo">
                {{ edit_errors.photo.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name">
                {{ edit_errors.name.join(" ") }}
              </p>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name"
              />
              <label for="name">Name </label>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="email"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              E-mail address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
              autocomplete="off"
              v-model="edit_data.email"
            />
            <span class="error">
              {{ (edit_errors.email || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="mobile"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Mobile number
            </label>
            <vue-tel-input
              id="mobile"
              ref="customerMobile"
              v-model="edit_data.mobile"
              @open="handleOpen('customerMobile')"
            ></vue-tel-input>
            <span class="error">
              {{ (edit_errors.mobile || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="password"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Password
            </label>
            <div class="relative">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded text-sm"
                autocomplete="off"
                v-model="edit_data.password"
              />
              <button
                class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                type="button"
                @click.prevent="textPasswordType"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>

            <span class="error">
              {{ (edit_errors.password || []).join(" ") }}
            </span>
          </div>
          <div class="space-y-2">
            <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
              Gender
            </p>
            <ul class="space-y-4">
              <li class="checkbox">
                <input
                  type="radio"
                  name="genderGroup"
                  id="genderMale"
                  value="male"
                  v-model="edit_data.gender"
                />
                <label
                  for="genderMale"
                  class="text-sm font-medium rtl:font-semibold capitalize"
                  >Male</label
                >
              </li>
              <li class="checkbox">
                <input
                  type="radio"
                  name="genderGroup"
                  id="genderFemale"
                  value="female"
                  v-model="edit_data.gender"
                />
                <label
                  for="genderFemale"
                  class="text-sm font-medium rtl:font-semibold capitalize"
                  >Female</label
                >
              </li>
            </ul>
            <p class="error">
              {{ (edit_errors.gender || []).join(" ") }}
            </p>
          </div>
          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Modal from "@/components/basics/Modal.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";

export default {
  name: "Customers",
  data() {
    return {
      load: false,
      items: [],
      add_data: {},
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      status: ["all", "on", "off"],
      pagination: {
        status: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        const uploader = this.$refs.uploader;
        this.add_data = {};
        this.add_errors = {};
        uploader.value = null;
      } else {
        this.add_data = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "customers",
          options: {
            ...others,
            status: others.status ? others.status : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.customers;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(`customers/update_status/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`customers/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (item || typeof item === "number") {
            if (key === "photo") {
              if (typeof item !== "object") {
                fd.delete(key);
              } else {
                fd.append(key, item, item.name);
              }
            } else if (key === "mobile") {
              fd.append(key, item.replace(/[+|\s|()|-]/g, ""));
            } else {
              fd.append(key, item);
            }
          }
        }
        const { data } = await this.axios.post("customers/add", fd);
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.$refs.uploader.value = null;

        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};
        let fd = new FormData();

        for (const key in this.edit_data) {
          const item = this.edit_data[key];
          if (item || typeof item === "number") {
            if (key === "photo") {
              if (typeof item !== "object") {
                fd.delete(key);
              } else {
                fd.append(key, item, item.name);
              }
            } else if (key === "mobile") {
              fd.append(key, item.replace(/[+|\s|()|-]/g, ""));
            } else {
              fd.append(key, item);
            }
          }
        }
        const { data } = await this.axios.post("customers/update", fd);
        this.edit_data = {};
        this.edit_active = false;
        this.$refs.uploader.value = null;

        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        console.log(error);
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },

    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        module_id: item?.module,
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "photo", null);
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { ButtonWithLoader, Modal },
};
</script>
