<template>
  <div>
    <section
      class="w-full flex flex-col lg:flex-row gap-6 p-6 max-h-screen min-h-screen"
    >
      <aside
        class="w-full lg:w-1/4 xl:max-w-sm flex flex-col"
        ref="settingsRefParent"
      >
        <button
          class="w-full px-4 py-2 rounded ring-1 ring-gray-200 bg-white flex lg:hidden items-center gap-4"
          @click.prevent="openSettingsDropdown"
        >
          <div class="flex-1">
            <p
              class="text-sm font-semibold rtl:font-bold text-gray-600 text-left rtl:text-right"
            >
              Tickets
            </p>
          </div>
          <div>
            <button class="w-8 h-8 rounded-full bg-gray-50 hover:bg-gray-100">
              <i class="fa-solid fa-angle-down fa-sm"></i>
            </button>
          </div>
        </button>
        <div class="w-full space-y-6 hidden lg:block flex-1 overflow-y-auto">
          <div class="w-full sticky top-0">
            <input
              type="text"
              name="search"
              id="search"
              class="w-full bg-white h-12 rounded-md px-4 text-sm pl-10 rtl:pl-auto rtl:pr-10"
              placeholder="Search in tickets..."
            />
            <span
              class="absolute top-0 left-0 rtl:left-auto rtl:right-0 h-full w-10 flex items-center justify-center text-gray-500 text-sm"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="false"></i>
              <i class="fa-solid fa-search" v-else></i>
            </span>
          </div>
          <div class="w-full space-y-4">
            <router-link
              v-for="i in 10"
              :key="i"
              :to="`/support/${i}`"
              class="block"
            >
              <figure class="w-full rounded-xl bg-white">
                <blockquote class="p-5 flex gap-4">
                  <div>
                    <div
                      class="w-12 h-12 rounded-full bg-gray-200 text-gray-600 flex items-center justify-center"
                    >
                      <i class="fa-solid fa-ticket"></i>
                    </div>
                  </div>
                  <div class="space-y-1 flex-1">
                    <h1 class="font-semibold rtl:font-bold">New orders</h1>
                    <p
                      class="text-xs font-medium rtl:font-semibold text-gray-600 line-clamp-2"
                    >
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Sapiente culpa quo quae, eligendi dicta tempora expedita
                      pariatur veniam corrupti? Reiciendis praesentium assumenda
                      quae voluptatem libero doloremque magni perferendis ex
                      corporis!
                    </p>
                    <p
                      class="text-xs font-medium rtl:font-semibold text-gray-600 !mt-2"
                    >
                      <span class="text-primary">Open</span>
                      •
                      <span>{{
                        new Date() | moment(" ddd DD MMM, YYYY")
                      }}</span>
                    </p>
                  </div>
                </blockquote>
              </figure>
            </router-link>
          </div>
        </div>
      </aside>
      <main class="w-full lg:w-3/4 xl:flex-1 h-full">
        <slot></slot>
      </main>
    </section>
  </div>
</template>

<script>
export default {
  name: "ContainerWrapper",

  methods: {
    openSettingsDropdown() {
      const parent = this.$refs.settingsRefParent;
      const dropdown = parent?.children[1];
      const currentChild = parent?.children[0];
      const icon = currentChild?.querySelector(".fa-sm");
      if (!parent || !dropdown || !currentChild) return;

      dropdown.classList.toggle("!block");
      currentChild.classList.toggle("mb-4");
      icon.classList.replace(
        dropdown.classList.contains("!block") ? "fa-angle-down" : "fa-angle-up",
        dropdown.classList.contains("!block") ? "fa-angle-up" : "fa-angle-down"
      );
    },
  },
};
</script>
