import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  ar: {
    app_name: "متجري",
  },
  en: {
    app_name: "Matgary",
  },
};

const i18n = new VueI18n({
  locale: "ar",
  fallbackLocale: "ar",
  messages,
});

export default i18n;
